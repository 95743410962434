import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { regExp, ErrorMessage } from 'src/app/shared/constant/global-constants';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  contactusform: FormGroup
  submitted = false

  constructor(
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService
  ) {

  }

  ngOnInit() {
    this.contactusform = this._formBuilder.group({
      name: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.pattern(regExp.emailRegEx)]],
      mobileNo: ['',[Validators.minLength(10),Validators.maxLength(10),Validators.pattern(regExp.numberRegEx)]],
      description: ['', Validators.required]
    })
  }

  SaveContactUs() {
    const controls = this.contactusform.controls;
      if (this.contactusform.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        return;
      }

      this._service.post_observe('/contactus', this.contactusform.value).subscribe(res => {
        if (res.status == 200) {
          this.contactusform.reset();
          this._toastrService.success(res.body["message"]);
        } else {
          this._toastrService.error(ErrorMessage);
        }
      })
  }
}
