import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-nearby-store',
  templateUrl: './nearby-store.component.html',
  styleUrls: ['./nearby-store.component.scss']
})
export class NearbyStoreComponent implements OnInit {

  constructor(private _service: CommonService) { }
  storeList = [];
  totalPage: number;
  currentPage: number = 1;
  loadpage = true;
  lat: any = '';
  lng: any = '';
  lat1: any = '';
  lng1: any = '';
  type: any = 'list';
  searchtext: string = '';
  isfromsearch: string = '1';
  storecategorylist: any;
  categoryID = [];
  userId = '';
  tabactive: any;
  totalrecord: number = 0;
  storeId: number;
  defaultImage: string = "assets/images/offer-discount-default.png";

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && user.userId && user.userId != '') {
      this.userId = user.userId
    }
    this._service.getPosition().then(pos => {
      this.lat = pos.lat;
      this.lng = pos.lng;
      if (window.history.state.store == undefined) {
        this.type = 'list';
      } else {
        this.type = 'map';
        this.storeId = window.history.state.store.uniqueId ? window.history.state.store.uniqueId : 0;
      }
      //  this.type = 'list';
      this.getstorecategorylist();
      this.getstorelist();
    });
  }
  switchstorelist(type) {
    this.type = type;
    this.storeList = [];
    this.storeId = 0;
    this.getstorelist();
  }

  getstorecategorylist() {
    this._service.get('getstorecategory').subscribe((res: any) => {
      this.storecategorylist = res.result.storeCategory;
    });
  }

  filterstorebycategory(cid) {
    if (this.tabactive == cid) {
      this.tabactive = '';
      this.categoryID = [];
    } else {
      this.tabactive = cid;
      this.categoryID = [];
      this.categoryID.push(cid);
    }
    this.loadpage = false;
    this.currentPage = 1;
    this.storeList = [];
    this.getstorelist();
  }

  getstorelist() {
    let data = {
      "latitude": this.lat,
      "longitude": this.lng,
      "PageNumber": this.currentPage,
      "type": this.type,
      "CategoryId": this.categoryID,
      "userId": this.userId,
      "searchKey": this.searchtext,
      "isFromSearch": this.isfromsearch
    }

    this._service.post('getstorelist', data).subscribe((res: any) => {
      res.result.storeList.forEach(store => {
        this.storeList.push(store);
      });
      this.totalrecord = res.result.totalStoreCount;
      this.totalPage = res.result.totalPages;
      this.loadpage = true;
    });
  }

  onScroll() {
    this.loadpage = !this.loadpage;
    if (this.currentPage) {
      this.currentPage = this.currentPage + 1;
      this.getstorelist();
    }
  }

  filterresult() {
    this.categoryID = [];
    this.loadpage = false;
    this.currentPage = 1;
    this.storeList = [];
    this.getstorelist();
  }

  infoWindowOpened = null
  previous_info_window = null
  close_window(e) {
    if (this.previous_info_window != null) {
      this.previous_info_window.close()
    }
  }
  select_marker(infoWindow) {
    if (this.previous_info_window == null)
      this.previous_info_window = infoWindow;
    else {
      this.infoWindowOpened = infoWindow
      this.previous_info_window.close()
    }
    this.previous_info_window = infoWindow
  }

}
