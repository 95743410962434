import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private _service: CommonService) { }

  faqlist: any;
  faqcategorylist: any;
  tabactive: any;
  ngOnInit() {
    this.getfaqcategorylist();
    
  }

  getfaqlist(cat) {
    this.tabactive = cat;
    let data = {"faqCategoryId": cat};
    this._service.post('getfaqlist',data).subscribe((res: any) => {
      this.faqlist = res.result.faqList;
    });
  }

  getfaqcategorylist() {
    this._service.get('getfaqcategorylist').subscribe((res: any) => {
      this.faqcategorylist = res.result.faqCategoryList;
      this.getfaqlist(this.faqcategorylist[0].faqCategoryId);
    });
  }

}
