export class SRUserDetail {
  userId: number;
  corporateUserId: number;
  firstName: string;
  lastName: string;
  userName: string;
  emailId: string;
  dateOfBirth: string;
  gender: string;
  password: string;
  cpassword:string;
  mobileNo:number;
  countryCode:any;

  clear() {
    this.userId = undefined;
    this.corporateUserId=undefined;
    this.firstName = '';
    this.lastName = '';
    this.userName = '';
    this.emailId = '';
    this.dateOfBirth = '';
    this.gender = '';
    this.password = '';
    this.cpassword = '';
    this.mobileNo = undefined;
    this.countryCode= '';
  }
}