import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-offer-and-discount',
  templateUrl: './offer-and-discount.component.html',
  styleUrls: ['./offer-and-discount.component.scss']
})
export class OfferAndDiscountComponent implements OnInit {

  constructor(private _service: CommonService,
    private _modalService: BsModalService,) { }

  _bsModalRef: BsModalRef;
  loadpage = true;
  offerList: any = [];
  offerId: any;
  defaultImage: string = "assets/images/offer-discount-default.png";
  ngOnInit() {
    this.getalloffer();
  }

  getalloffer() {
    let data = {
      offerType: 2
    }
    this._service.post('getalloffer',data).subscribe((res: any) => {
      this.offerList = res.result.offerList;
    });
  }

  detailopen(popup,offerId){
    this.offerId = offerId;
    this._modalService.show(popup, {class:'offer-detail'});
  }

}
