export const environment = {
  production: true,
  //API_ENDPOINT: 'http://180.211.99.238:9335/api/cyberworld/V1/',
  API_ENDPOINT: 'http://api.cyberworld.ng/api/cyberworld/V1/',
  //API_ENDPOINT_2: 'https://payment-api.staging.cyberpay.ng/api/v1/',
  API_ENDPOINT_2: 'https://payment-api.cyberpay.ng/api/v1/',
  //BILLER_KEY: "7b03633edd5b40a880bbef855159d31d",
  BILLER_KEY: "5704d9ab0e114746accc5db8e927821b",
  FB_URL: 'https://facebook.com/CyberPayNaija',
  TWITTER_URL: 'https://twitter.com/Cyberpaynaija',
  GOOGLE_URL: 'http://google.com',
  IN_URL: 'https://ng.linkedin.com/in/cyberspace-networks-new',
  ANDROID_APP_URL: 'https://play.google.com',
  IOS_APP_URL: 'https://www.apple.com',
  MAP_KEY: 'AIzaSyBjSQ4kc2ybtNDl0mn_Tu76ac9kXMYIfEM',
  OTP_TIME: 60,
  GPlUS_KEY: "276908591828-jfva6olbkt7kgkq9q6qrrvv06a8811fm.apps.googleusercontent.com",
  FB_ID: "518562198881140",
  SERVICE_UTILITY: 1,
  SERVICE_DTH: 2,
  SERVICE_MOBILE: 4,
  SERVICE_MOBILE_BILL: 8,
  PAYMENT_METHOD_WALLET: [{ key: 2, label: "Card" }],
  PAYMENT_METHOD_SERVICES: [{ key: 1, label: "Wallet" }, { key: 2, label: "Card" }], //{key:5,label:"Net Banking"}

};
