import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-services-status',
  templateUrl: './services-status.component.html',
  styleUrls: ['./services-status.component.scss']
})
export class ServicesStatusComponent implements OnInit {

  orderdetails: any;
  constructor(private _service: CommonService,
    private _toastrService: ToastrService, ) { }

  ngOnInit() {
    this.gettransactiondetail();
  }

  gettransactiondetail() {
    let transactionId = localStorage.getItem('transactionId');
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (transactionId) {
      let data = {
        "transactionId": transactionId,
        "userId": user.userId
      }
      this._service.post('gettransactiondetail/', data).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.orderdetails = res.result;
        } else {
          this._toastrService.error(res.message)
        }
      }, error => {
        this._toastrService.error('Something went wrong, please try again or after sometime.', 'Error')
      });
    } else {
      //console.log('not found');
    }
  }
}