import { Component, OnInit, Input, NgZone, ViewChild, ElementRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MapsAPILoader, MouseEvent  } from '@agm/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss']
})
export class MapPopupComponent implements OnInit {

  @Input() lat: any;
  @Input() lng: any;
  private geoCoder;
  zoom: number;
  address: string;
  @ViewChild('searchaddress', { static: false }) searchElementRef: ElementRef;

  constructor(private _modalService: BsModalService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _toastrService: ToastrService) { }

  ngOnInit(){

  }
  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      //console.log(autocomplete);
      autocomplete.addListener("place_changed", () => {
        //console.log(1)
        this.ngZone.run(() => {
          //console.log(2)
          //console.log(autocomplete.getPlace());
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //console.log('places', place);
          if (place.geometry === undefined || place.geometry === null) {
            //console.log(3)
            return;
          }
          //console.log(4)
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  closeModal() {
    this._modalService._hideModal(1);
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  markerDragEnd($event) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          this._toastrService.error('No results found', 'Error');
        }
      } else {
        this._toastrService.error('Geocoder failed due to: ' + status, 'Error');
      }
    });
  }
}
