import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';

@Component({
  selector: 'app-sr-qrcode-history',
  templateUrl: './sr-qrcode-history.component.html',
  styleUrls: ['./sr-qrcode-history.component.scss']
})
export class SrQrcodeHistoryComponent implements OnInit {
  srId;
  loggedinUserId;
  qrcodeHistoryList: any = [];
  searchtext: string = '';

  constructor(
    private _service: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  filterresult() {
    this.qrcodeHistoryList = [];
    this.getqrcodehistorylist();
  }

  ngOnInit() {
    var retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    this.loggedinUserId = retrievedUserObject["userId"];
    this.getqrcodehistorylist();
  
  }

  getqrcodehistorylist() {
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.srId = params['id'];
    });
    let id;
    if (this.srId) {
      id = this.srId
    } else {
      id = this.loggedinUserId;
    }
    let data = {
      "userId": id,
      "searchText": this.searchtext.trim()
    };
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
      this._service.post('getqrcodehistorylist', data).subscribe((res: any) => {
        if (res.status && res.result) {
          this.qrcodeHistoryList = res.result.qrCodeList;
        }
      });
    });


  }
}
