import { Component, OnInit,Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit {

  @Input() notificationDetails:any;
  notificationdata:any;
  constructor(private _modalService: BsModalService,) { }

  ngOnInit() {
    this.notificationdata = this.notificationDetails;
  }

  closeModal(){
    this._modalService._hideModal(1);
  }

}
