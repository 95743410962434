import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amount' })
export class AmountPipe implements PipeTransform {
  transform(value: any): any {
  if ((value === null) || (value === '')) {
      return '';
  } else {
      return value / 100;

  }
}
}