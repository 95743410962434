import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regExp } from 'src/app/shared/constant/global-constants';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;

  @ViewChild('formele', { static: false }) formele: ElementRef;

  submitform: boolean = false;
  billersvalidate: boolean = false;
  serviceslist: any;
  currentpage: string = window.location.pathname;
  currentpageId: number;
  billers: any;
  slideConfig = {
    "slidesToShow": 8, "slidesToScroll": 8, "dots": false, "infinite": true, "centerMode": false, "speed": 1000, responsive: [{ breakpoint: 1280, settings: { slidesToShow: 7, slidesToScroll: 1, } }, { breakpoint: 1200, settings: { slidesToShow: 6, slidesToScroll: 1, } }, {
      breakpoint: 992, settings: { slidesToShow: 4, slidesToScroll: 1, }
    }, { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1, } }, { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 1, } }, { breakpoint: 375, settings: { slidesToShow: 1, slidesToScroll: 1, } }]
  };
  billerId: number;
  billerName: string = '';
  billerImagedefault: string = 'assets/images/recharge-default-services.png';
  billerImage: string = this.billerImagedefault;
  billerDescription: string = '';
  paymentitems: any;
  customers: any;
  isamountdisabled: boolean = false;
  selectbillererror: boolean = false;
  paymentitemname: string = '';
  currencySymbol: string = 'NGN';
  amount: number;
  customerId: string;
  initrecharge: FormGroup;
  paymenttype: any = environment.PAYMENT_METHOD_SERVICES;
  service_mobile = environment.SERVICE_MOBILE;
  service_mobile_bill = environment.SERVICE_MOBILE_BILL;
  validatecustomer: boolean = true;
  showpagkage: boolean = false;
  showloginerror: boolean = false;
  tempimg: string;
  customeridlable: string;
  pin: number;
  user: any;
  constructor(private _service: CommonService,
    private _toastrService: ToastrService,
    private _route: Router,
    private _fromBuilder: FormBuilder,
    private _activeroute: ActivatedRoute,
    private myElement: ElementRef) {
    this.initrecharge = this._fromBuilder.group({
      "userId": ['', Validators.required],
      "amount": ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      "billerId": ['', Validators.required],
      "billerName": ['', Validators.required],
      "paymentCode": ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      "customerId": ['0000000001', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      "instrumentType": ["2", [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      "billerType": '', // blank or Mobile
      "serviceId": ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      "description": '',
      "deviceTypeId": 3,
      "packageId": ['', Validators.required],
      "returnURL": [window.location.origin + '/services-status', Validators.required],
    });
    this._activeroute.params.subscribe(
      (params: any) => {
        this.currentpage = window.location.pathname;
        this.ngOnInit();
      }
    );
  }
  @Input() src: string;
  ngOnInit() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.billerImage = this.billerImagedefault;
    this.billerDescription = "";
    this.billerName = "";
    this.initrecharge.patchValue({ customerId: "", amount: "" });
    this.showpagkage = false;
    this.paymentitems = [];
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this._service.currentMessage.subscribe(services => {
      this.serviceslist = services
      this.getcurrentpageid();
      this.getbillerscategory();
    })
  }

  setdefaultimage(imgtemp) {
    imgtemp.src = 'assets/images/1.png';
  }

  gotoTop() {
    this.formele.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  initiatebillpayment() {
    this.showloginerror = false;
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.selectbillererror = false;
    this.submitform = true;
    this.billersvalidate = false;
    this.validatecustomer = true;
    if (!user || !user.userId) {
      this.showloginerror = true;
      return;
    }
    if (this.paymentitems && this.paymentitems.length > 0) {
      if (this.paymentitems == undefined || this.paymentitemname == "") {
        this.paymentitemname = this.paymentitems[0].paymentitemname ? this.paymentitems[0].paymentitemname : '';
      }
      this.currencySymbol = this.paymentitems[0].currencySymbol;
      if (this.isamountdisabled) {
        this.initrecharge.patchValue({ amount: this.amount });
      } else {
        this.amount = this.initrecharge.get('amount').value;
      }
      this.customerId = this.initrecharge.get('customerId').value;
      this.initrecharge.patchValue({
        userId: user.userId,
        billerId: this.billerId,
        billerName: this.billerName,
        paymentCode: this.paymentitems[0].paymentCode,
        billerType: "",
        serviceId: this.currentpageId,
        description: "test web",
      });
      if (!this.initrecharge.valid) {
        return false;
      }
      let data = {
        "customers": [
          {
            "customerId": this.customerId,
            "paymentCode": this.paymentitems[0].paymentCode
          }
        ]
      }
      this._service.post_2('billers/customer/validate/', data).subscribe((res: any) => {
        if (res.code == 'PY00' && res.succeeded) {
          this.customers = res.data.customers;
          if (this.customers[0].responseCode == 90000) {
            this.billersvalidate = true;
            this.steptwo();
            this.validatecustomer = true;
          } else {
            this.validatecustomer = false;
          }
        } else {
          this._toastrService.error(res.message)
        }
      }, error => {
        this._toastrService.error('Something went wrong, please try again or after sometime.', 'Error')

      });
    } else {
      this.selectbillererror = true;
    }
  }

  steptwo() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.gotoTop();
  }

  stepthree() {
    this.step2 = false;
    this.step3 = true;
    this.step1 = false;
    this.gotoTop();
  }

  initpayment() {
    if (!this.initrecharge.valid) {
      return false;
    }
    if (this.initrecharge.get("instrumentType").value == 1) {
      this.step3 = false;
      this.step4 = true;
    } else {
      this.initpayapi('initiatebillpayment');
    }
  }

  verifyandpay() {
    this.submitform = true;
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this._service.post('verifypin', { userId: user.userId, pin: this.pin }).subscribe((res: any) => {
      if (res.statusCode == 200 && res.result == true) {
        this.initpayapi('initiatebillpaymentwithwallet');
      } else {
        this._toastrService.error(res.message);
      }
    }, error => {
      let fieldError = "";
      if (error.error.message) {
        fieldError = error.error.message;
      } else if (error.message) {
        fieldError = error.message;
      } else {
        fieldError = "Something went wrong, please try again or after sometime."
      }
      this._toastrService.error(fieldError, 'Error');
    });
  }

  initpayapi(endpoint) {
    this._service.post(endpoint, this.initrecharge.value).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem('amount', this.initrecharge.get('amount').value);
        localStorage.setItem('instrumentType', this.initrecharge.get('instrumentType').value);
        if (this.initrecharge.get("instrumentType").value == 1) {
          this._route.navigate(['/services-status']);
        } else {
          window.location.href = res.result.paymentRedirectURL;
        }
      } else {
        this._toastrService.error(res.message);
      }
    }, error => {
      let fieldError = "";
      if (error.error.message) {
        fieldError = error.error.message;
      } else if (error.message) {
        fieldError = error.message;
      } else {
        fieldError = "Something went wrong, please try again or after sometime."
      }
      this._toastrService.error(fieldError, 'Error');
    });
  }

  getcurrentpageid() {
    this.serviceslist.forEach(service => {
      if ('/services/' + service.categoryName.toLowerCase().replace(/ /g, '-') == window.location.pathname) {
        this.currentpageId = service.categoryId;
      }
    });
    if (this.currentpageId == environment.SERVICE_MOBILE_BILL || this.currentpageId == environment.SERVICE_MOBILE) {
      this.customeridlable = "Mobile Number";
    } else {
      this.customeridlable = "Consumer ID";
    }
  }

  changecurrentpage(page, pageID) {
    this.currentpage = '/services/' + page.toLowerCase().replace(/ /g, '-');
    this.billers = null;
    this.currentpageId = pageID;
    //this.getbillerscategory();
  }

  selectBiller(biller) {
    this.paymentitemname = "";
    this.billerId = biller.billerid;
    this.billerImage = this.billerImagedefault;
    this.billerName = "";
    this.billerDescription = "";
    this.showpagkage = false;
    this.paymentitems = undefined;
    this.isamountdisabled = false;
    this._service.get_2('billers/' + this.billerId + '/paymentItems/').subscribe((res: any) => {
      if (res.code == 'PY00') {
        this.billerImage = biller.imageBaseUrl + '/' + biller.logoUrl;
        this.billerName = biller.billername;
        this.billerDescription = biller.categorydescription;
        this.selectbillererror = false;
        this.paymentitems = res.data.paymentitems;
        this.isamountdisabled = this.paymentitems[0].isAmountFixed;
        if (this.isamountdisabled) {
          this.amount = this.paymentitems[0].amount;
          this.initrecharge.controls['amount'].disable();
        } else {
          this.initrecharge.controls['amount'].enable();
        }
        if (this.currentpageId == environment.SERVICE_UTILITY) { // electricity bill payment // utility bill payment
          this.initrecharge.patchValue({ amount: '', packageId: this.paymentitems[0].paymentitemid });
          this.showpagkage = false;
        } else {
          this.initrecharge.patchValue({ amount: this.paymentitems[0].amount, packageId: this.paymentitems[0].paymentitemid });
          this.showpagkage = true;
        }
      } else {
        this._toastrService.error(res.message)

      }
    }, error => {
      this._toastrService.error('Something went wrong, please try again or after sometime.', 'Error')

    });

  }

  setamount(pack) {
    this.isamountdisabled = pack.isAmountFixed;
    this.paymentitemname = pack.paymentitemname;
    if (pack.isAmountFixed) {
      this.amount = pack.amount;
      this.initrecharge.controls['amount'].disable();
    } else {
      this.initrecharge.controls['amount'].enable();
    }
    if (this.currentpageId == environment.SERVICE_UTILITY) { // electricity bill payment // utility bill payment
      this.initrecharge.patchValue({ packageId: pack.paymentitemid });
      this.showpagkage = false;
    } else {
      this.initrecharge.patchValue({ amount: pack.amount, packageId: pack.paymentitemid });
      this.showpagkage = true;
    }
  }

  getbillerscategory() {
    if (this.currentpageId) {
      this._service.get_2('billers/category/' + this.currentpageId).subscribe((res: any) => {
        if (res.code == 'PY00') {
          this.billers = res.data.billers;
        } else {
          this._toastrService.error(res.message)
        }
      }, error => {
        this._toastrService.error('Something went wrong, please try again or after sometime.', 'Error')
      });
    }
  }

  backtostepone() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.gotoTop();
  }

  backtosteptwo() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.step4 = false;
    this.gotoTop();
  }

  backtostepthree() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
    this.step4 = false;
    this.gotoTop();
  }
}
