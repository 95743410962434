import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  constructor(private commonservice: CommonService) { }
  loadertest: boolean = false;
  ngOnInit() {
  }

  ngDoCheck(){
    this.loadertest = this.commonservice.loadershow;
  }

}