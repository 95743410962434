import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    transactionHistoryList: any = [];
    noOfPayment: number;
    amountReceived: number;
    lblPaidToText: string;
    lblPaidto: string;
    lblPaidimg: string;
    Statusclass: string;
    maxDate = new Date();

    constructor(
        private _service: CommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        let user_data = JSON.parse(localStorage.getItem('currentUser'));
        let userId = user_data.userId;
        let curr_date = moment().format("YYYY-MM-DD");
        this.getALlcount(userId, curr_date);
        this.loadList(userId, curr_date);
    }
    loadList(userId, curr_date) {
        let user_data = JSON.parse(localStorage.getItem('currentUser'));
        let data = {
            "userId": userId,
            "date": curr_date
        };
        this._service.post('getcorporatehistorybydate', data).subscribe((res: any) => {
            if (res.status && res.result) {
                res.result.transactionHistoryList.map(function (a, b) {
                    if (a.tranTypeName == "Debit") {
                        a.lblPaidToText = "Paid To";
                        a.lblPaidto = a.paidTo;
                        a.lblPaidfrom = a.paymentFrom;
                    } else if (a.tranTypeName == "WalletTopup") {
                        a.lblPaidToText = "Wallet Topup";
                        a.lblPaidto = a.paymentFrom;
                        a.lblPaidfrom = a.paidTo;
                    }
                    else if (a.tranTypeName == "Credit") {
                        if (user_data.userType == 2) {
                            a.lblPaidToText = "Paid To";
                            a.lblPaidto = a.paidTo;
                            a.lblPaidfrom = a.paymentFrom;
                        } else {
                            a.lblPaidToText = "Paid By";
                            a.lblPaidto = a.paymentFrom;
                           a.lblPaidfrom = a.paidTo;
                        }
                    }
                    else {
                        a.lblPaidToText = "Paid To";
                        a.lblPaidto = a.paidTo;
                        a.lblPaidfrom = a.paymentFrom;
                    }
                    if (a.transactionStatus == "Success") {
                        a.Statusclass = "success";
                    } else if (a.transactionStatus == "Failed") {
                        a.Statusclass = "fail";
                    } else {
                        a.Statusclass = "";
                    }
                    a.lblPaidimg = a.transactionMode == "Wallet" ? "assets/images/credited01.png" : "assets/images/icon_card.png";
                });
                this.transactionHistoryList = res.result.transactionHistoryList;
                this.changeDetectorRef.markForCheck();
            }
        });
    }
    getALlcount(userId, curr_date){
        let data = {
            "userId": userId,
            "date": curr_date
        };
        this._service.post('getcorporatepaymentcount', data).subscribe((res: any) => {
            if (res.status && res.result) {
                this.noOfPayment = parseInt(res.result.noOfPayment);
                this.amountReceived = res.result.amountReceived;
            }
        });
    }
    ondateValueChange(value: Date): void {
        let user_data = JSON.parse(localStorage.getItem('currentUser'));
        let userId = user_data.userId;
        let curr_date = moment(value).format("YYYY-MM-DD");
        this.getALlcount(userId, curr_date);
        this.loadList(userId, curr_date);
        
    }
    transacation_details(transacationid) {
        this.router.navigate(['/transaction-history/', transacationid], { relativeTo: this.activatedRoute });
    }
}