import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { Aboutus_slug } from '../../shared/constant/global-constants';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  constructor(private _service: CommonService) { }

  aboutusdetails: any;
  
  ngOnInit() {
    let data = {"pageSlug": Aboutus_slug};
    this._service.post('getpagecontentbyslug',data).subscribe((res: any) => {
      this.aboutusdetails = res.result;
    });
  }

}
