import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pagination-detail',
  templateUrl: './pagination-detail.component.html',
  styleUrls: ['./pagination-detail.component.scss']
})
export class PaginationDetailComponent implements OnInit {

  @Input() totalItems: number
  @Input() itemsPerPage: number
  @Input() currentPageNumber: number

  from: number = 0
  to: number = 0
  total: number = 0

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    this.total = this.totalItems

    if(this.totalItems <= this.itemsPerPage) {
      this.from = 1
      this.to = this.total
    } else if(this.itemsPerPage*this.currentPageNumber >= this.total) {
      this.from = (this.itemsPerPage*this.currentPageNumber)-this.itemsPerPage+1
      this.to = this.total
    } else {
      this.from = (this.itemsPerPage*this.currentPageNumber)-this.itemsPerPage+1
      this.to = this.itemsPerPage*this.currentPageNumber
    }
  }
}
