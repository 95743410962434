import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SRUserDetail } from './../../models/sr-user';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-manage-srusers',
  templateUrl: './manage-srusers.component.html',
  styleUrls: ['./manage-srusers.component.scss']
})

export class ManageSRUsersComponent implements OnInit {

  userList: any = [];
  userDetail: any;
  _bsModalRef: BsModalRef;
  loadpage = true;
  title: any;
  message: any;

  constructor(
    public _modalService: BsModalService,
    private _service: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.getAllSRUsers();
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => {
      this._modalService.onHide.subscribe((e) => {
        this.getAllSRUsers();
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  getAllSRUsers() {
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    let userId = user_data.userId;
    let data = {
      "corporateUserId": userId
    }
    this._service.post('getsalesrepresentative', data).subscribe((res: any) => {
      this.userList = res.result.salesRepresentativeList;
    });
    this.changeDetectorRef.markForCheck();
  }

  addModelopen(popup, user?: SRUserDetail) {
    this.userDetail = user;
    this._bsModalRef = this._modalService.show(popup, { class: 'offer-detail', backdrop: 'static' });
  }
  sr_qrcode_history(userId) {
    this.router.navigate(['/sr-qrcode-history/', userId], { relativeTo: this.activatedRoute });
  }
  sr_transacation_history(userId) {
    this.router.navigate(['/sr-transacation-history/', userId], { relativeTo: this.activatedRoute });
  }
  block_sr_user(userId) {
    this.title = 'Confirmation';
    this.message = 'Are you sure want to delete this Sales representative?';
    this._bsModalRef = this._modalService.show(BlockComponent, { class: 'offer-detail', backdrop: 'static', initialState: { title: this.title, message: this.message, userId: userId } });
  }
  deactivate_sr_user(userId, status, msg) {
    this.title = 'Confirmation';
    this._bsModalRef = this._modalService.show(DeactivateComponent, { class: 'offer-detail', backdrop: 'static', initialState: { title: this.title, message: msg, status: status, userId: userId } });
  }
}

@Component({
  selector: 'block',
  template: `
    <div class="modal-content">
      <div class="modal-header">
        <h4>{{title || 'Confirm'}}</h4>
      </div>
      <div class="modal-body">
        <p>{{message || 'Are you sure?'}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirm(userId)">OK</button>
      </div>
    </div>
  `
})
export class BlockComponent {
  title: any;
  message: any;
  userId: number;
  constructor(private _service: CommonService, 
    private _toastrService: ToastrService,
    public _modalService: BsModalService,
   ) {
  }
  confirm(userId) {
    let data = {
      "userId": userId,
      "blockReason": "Suspicious Activity found"
    }
    this._service.post('blocksalesrepresentative', data).subscribe((res: any) => {
      if (res.status) {
        this._toastrService.success(res.message, 'Success');
        this._modalService._hideModal(1);
      } else {
        this._toastrService.error(res.message, 'Error');
        this._modalService._hideModal(1);
      }
    });
    
  }
  cancel() {
    this._modalService._hideModal(1);
  }
}

@Component({
  selector: 'deactivate',
  template: `
    <div class="modal-content">
      <div class="modal-header">
        <h4>{{title || 'Confirm'}}</h4>
      </div>
      <div class="modal-body">
        <p>Are you sure want to {{message}} this Sales representative?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirm(userId,status)">OK</button>
      </div>
    </div>
  `
})
export class DeactivateComponent {
  title: any;
  message: any;
  userId: number;
  status: number;
  constructor(private _service: CommonService, 
    private _toastrService: ToastrService,
    public _modalService: BsModalService,
   ) {
  }
  confirm(userId, status) {
    // on click on confirm button we set dialog result as true,
    // ten we can get dialog result from caller code
    let data = {
      "userId": userId,
      "isActive": status
    }
    this._service.post('changestatussalesrepresentative', data).subscribe((res: any) => {
      if (res.status) {
        this._toastrService.success(res.message, 'Success');
        this._modalService._hideModal(1);
      } else {
        this._toastrService.error(res.message, 'Error');
        this._modalService._hideModal(1);
      }
    });
  }
  
  cancel() {
    this._modalService._hideModal(1);
  }
}
