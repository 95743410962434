import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Angular Boilerplate';

  constructor(
    private _titleService: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    /**
     * following code is use for set current page title
     */
    const appTitle = this._titleService.getTitle()
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this._activatedRoute.firstChild;
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title']
        }
        return appTitle
      })
    ).subscribe((ttl: string) => {
      this._titleService.setTitle(ttl)
    });
  }
}
