import { HeaderTitle } from './shared/constant/global-constants';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { FaqComponent } from './components/faq/faq.component';
import { NearbyStoreComponent } from './components/nearby-store/nearby-store.component';
import { TermsConditionsComponent } from 'src/app/components/terms-conditions/terms-conditions.component';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { ChangePasswordComponent } from 'src/app/components/change-password/change-password.component';
import { MyProfileComponent } from 'src/app/components/my-profile/my-profile.component';
import { TermsConditionsMobileComponent } from './components/terms-conditions-mobile/terms-conditions-mobile.component';
import { PrivacyPolicyMobileComponent } from './components/privacy-policy-mobile/privacy-policy-mobile.component';
import { AboutusMobileComponent } from './components/aboutus-mobile/aboutus-mobile.component';
import { MainHomeComponent } from './components/main-home/main-home.component';
import { OfferAndDiscountComponent } from 'src/app/components/offer-and-discount/offer-and-discount.component';
import { GetAppComponent } from './components/get-app/get-app.component';
import { ServicesDetailsComponent } from './components/services-details/services-details.component';
import { TransacationDetailsComponent } from './components/transacation-details/transacation-details.component';
import { ManageSRUsersComponent } from './components/manage-srusers/manage-srusers.component';
import { SrQrcodeHistoryComponent } from './components/manage-srusers/sr-qrcode-history/sr-qrcode-history.component';
import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';
import { SrTransacationHistoryComponent } from './components/manage-srusers/sr-transacation-history/sr-transacation-history.component';

import { RevenueReportComponent } from './components/revenue-report/revenue-report.component';

import { ServicesStatusComponent } from './components/services-status/services-status.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { NoaccessComponent } from './components/noaccess/noaccess.component';
import { PermissionGuard } from '../app/shared/guards/permission.guard';
import { ChangePinComponent } from './components/change-pin/change-pin.component';
import { ForgotPinComponent } from './components/forgot-pin/forgot-pin.component';
import { WalletStatusComponent } from './components/wallet-status/wallet-status.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
const routes: Routes = [
  {
    path: 'term-condition-mobile',
    component: TermsConditionsMobileComponent
  },
  {
    path: 'privacy-policy-mobile',
    component: PrivacyPolicyMobileComponent
  },
  {
    path: 'about-cyberworld-mobile',
    component: AboutusMobileComponent
  },
  {
    path: '',
    component: MainHomeComponent,
    //canActivate: [PermissionGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: HeaderTitle + ' | Home' }
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [PermissionGuard],
        data: { title: HeaderTitle + ' | Dashboard' }
      },
      {
        path: 'about-cyberworld',
        component: AboutusComponent,
        data: { title: HeaderTitle + ' | AboutUs' }
      },
      {
        path: 'contact-us',
        component: ContactusComponent,
        data: { title: HeaderTitle + ' | ContactUs' }
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: { title: HeaderTitle + ' | Privacy Policy' }
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { Title: HeaderTitle + ' | FAQ' }
      },
      {
        path: 'nearby-store',
        component: NearbyStoreComponent,
        data: { Title: HeaderTitle + ' | Near By Store' }
      },
      {
        path: 'term-condition',
        component: TermsConditionsComponent,
        data: { title: HeaderTitle + ' | Terms & Conditions' }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: { Title: HeaderTitle + ' | Settings' }
      },
      {
        path: 'withdraw',
        component: WithdrawComponent,
        data: { Title: HeaderTitle + ' | Withdraw' }
      },
      {
        path: 'change-password',
        canActivate: [AuthGuard],
        component: ChangePasswordComponent,
        data: { Title: HeaderTitle + ' | Settings' }
      },
      {
        path: 'change-pin',
        component: ChangePinComponent,
        canActivate: [AuthGuard],
        data: { Title: HeaderTitle + ' | Settings' }
      },
      {
        path: 'forgot-pin',
        component: ForgotPinComponent,
        canActivate: [AuthGuard],
        data: { Title: HeaderTitle + ' | Settings' }
      },
      {
        path: 'my-profile/edit',
        component: MyProfileComponent,
        canActivate: [AuthGuard],
        data: { Title: HeaderTitle + ' | Edit My Profile' }
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard],
        data: { Title: HeaderTitle + ' | My Profile' }
      },
      {
        path: 'my-dashobard',
        component: DashboardComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | My Dashboard' }
      }, {
        path: 'manage-sr-users',
        component: ManageSRUsersComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | Manage SR Users' }
      },
      {
        path: 'sr-qrcode-history',
        component: SrQrcodeHistoryComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | SR QR Code History' }
      },
      {
        path: 'sr-qrcode-history/:id',
        component: SrQrcodeHistoryComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | Sr QR Code History' }
      },
      {
        path: 'sr-transacation-history',
        component: SrTransacationHistoryComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | Sr Transacation History' }
      },
      {
        path: 'sr-transacation-history/:id',
        component: SrTransacationHistoryComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | Sr Transacation History' }
      },
      {
        path: 'transaction-history',
        component: TransactionHistoryComponent,
        canActivate: [AuthGuard],
        data: { Title: HeaderTitle + ' | Transaction History' }
      },
      {
        path: 'transaction-history/:id',
        component: TransacationDetailsComponent,
        canActivate: [AuthGuard],
        data: { Title: HeaderTitle + ' | Transaction History' }
      },
      {
        path: 'transaction-report',
        component: TransactionReportComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | Transaction Report' }
      },
      {

        path: 'revenue-report',
        component: RevenueReportComponent,
        canActivate: [PermissionGuard],
        data: { Title: HeaderTitle + ' | Revenue Report' }
      },
      {
        path: 'offer-and-discount',
        component: OfferAndDiscountComponent,
        data: { Title: HeaderTitle + ' | Offer and Discount' }
      },
      {
        path: 'get-app',
        component: GetAppComponent,
        data: { Title: HeaderTitle + ' | Get App' }
      },
      {
        path: 'services/:name',
        component: ServicesDetailsComponent,
        data: { Title: HeaderTitle + ' | Services' }
      },
      
      {
        path: 'services-status',
        component: ServicesStatusComponent,
        data: { Title: HeaderTitle + ' | Order Status' }

      },
      {
        path: 'wallet-status',
        component: WalletStatusComponent,
        data: { Title: HeaderTitle + ' | Order Status' }

      },
      {
        path: 'noaccess',
        component: NoaccessComponent,
        data: { Title: HeaderTitle + ' | NO Access' }
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: HeaderTitle + ' | 404' }
      }
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: HeaderTitle + ' | 404' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
