import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';

import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from "@angular/common/http";
import { throwError, Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { CommonService } from '../../services/common/common.service';
import { AuthService } from '../../auth/service/auth.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	constructor(private service: CommonService,private _authService: AuthService,private _toastrService: ToastrService,) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem('token');
		if (token) {
			request = request.clone({ headers: request.headers.set('Token', token) });
		}
		request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
		//request = request.clone({ headers: request.headers.set('Origin1', 'web') });
		
		this.service.showloader();
		return next.handle(request).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				this.service.hideloader();
			}
			return event;
		}), catchError((error: HttpErrorResponse) => {
			if (error.status == 401) {
				this._toastrService.error(error.error.message)
				setTimeout(() => {
					this._authService.logout();
				}, 5000);  //5s
			}
			this.service.hideloader();
			return throwError(error);
		}))
	}
}
