import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/service/auth.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  serviceslist: any;
  constructor(
    public _authService: AuthService,
    private _service: CommonService
  ) { }

  ngOnInit() {
    this._authService.isUserLoggedIn()
    this.getcategorylist();
  }

  getcategorylist() {
    let data = { "offerType": 1 };
    this._service.get('getcategorylist').subscribe((res: any) => {
      this.serviceslist = res.result.categoryList;
      this._service.changeMessage(this.serviceslist);
    });
  }

}
