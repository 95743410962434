import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
    constructor(
        private _router: Router,
    ) { }
    canActivate(active: ActivatedRouteSnapshot, route: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
       
        let user_data = JSON.parse(localStorage.getItem('currentUser'));
        if(user_data != null){
            let userType = user_data.userType;
            if (userType && userType == 1) {
                this._router.navigateByUrl('/noaccess');
                return false;
            }else{
                return true;
            }
        }else{
            this._router.navigateByUrl('/noaccess');
            return false;
        }
    }

}
