import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { QRCodeModule } from 'angularx-qrcode';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';


@Component({
  selector: 'app-sr-transacation-history',
  templateUrl: './sr-transacation-history.component.html',
  styleUrls: ['./sr-transacation-history.component.scss']
})
export class SrTransacationHistoryComponent implements OnInit {

  @ViewChild('formele', { static: false }) formele: ElementRef;

  transacationHistoryList: any = [];
  retrievedUserObject: any;
  pageSize: number = 10;
  collectionSize: number;
  page: number = 1;
  orderBy: string = 'desc';
  orderByItem: string = 'transactionId';
  _filter: any = {};
  srId;
  loggedinUserId;
  Statussorting_class: string;
  Datesorting_class: string;
  Amountsorting_class: string;

  constructor(
    private _service: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _toastrService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    this.loggedinUserId = this.retrievedUserObject["userId"];
    this.get_page();
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.get_page();
    this.gotoTop();
  }
  gotoTop() {
    this.formele.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  get_page() {
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.srId = params['id'];
    });
    let id;
    if (this.srId) {
      id = this.srId
    } else {
      id = this.loggedinUserId;
    }
    this._filter.userId = id;
    this._filter['pageNumber'] = Number(this.page) - 1;
    this._filter['pageSize'] = this.pageSize;
    this._filter['sortField'] = this.orderByItem;
    this._filter['sortOrder'] = this.orderBy;
    this.gettransacationhistorylist();

  }
  sorting(sortField, sortOrder) {
    this.orderByItem = sortField;
    this.orderBy = sortOrder;
    this.page = 1;
    if (sortField == "transactionDate" && sortOrder == "asc") {
      this.Datesorting_class = 'Dateasc';
    } else {
      this.Datesorting_class = "Datedesc"
    }
    if (sortField == "transactionAmount" && sortOrder == "asc") {
      this.Amountsorting_class = 'Amountasc';
    } else {
      this.Amountsorting_class = "Amountdesc"
    }
    if (sortField == "transactionStatus" && sortOrder == "asc") {
      this.Statussorting_class = 'Statusasc';
    } else {
      this.Statussorting_class = "Statusdesc"
    }
    this.get_page();
  }

  gettransacationhistorylist() {
    of(undefined).pipe(take(1), delay(200)).subscribe(() => {
      this._service.post('getscanqrcodetransactionhistory', this._filter).subscribe((data: any) => {
        if (data.statusCode == 200) {
          data.result.qrCodeTranHistory.map(function (a, b) {
            if (a.tranTypeName == "Debit") {
              a.lblPaidToText = "Paid To";
              a.lblPaidto = a.paidTo;
            } else if (a.tranTypeName == "Wallet") {
              a.lblPaidToText = "Wallet Topup";
              a.lblPaidto = a.paymentFrom;
            }
            else if (a.tranTypeName == "Credit") {
              if (this.retrievedUserObject["userType"] == 2) {
                a.lblPaidToText = "Paid To";
                a.lblPaidto = a.paidTo;
              } else {
                a.lblPaidToText = "Paid By";
                a.lblPaidto = a.paymentFrom;
              }
            }
            else {
              a.lblPaidToText = "Paid To";
              a.lblPaidto = a.paidTo;
            }
            if (a.transactionStatus == "Success") {
              a.Statusclass = "success";
            } else if (a.transactionStatus == "Failed") {
              a.Statusclass = "fail";
            } else {
              a.Statusclass = "";
            }
            a.lblPaidimg = a.transactionMode == "Wallet" ? "assets/images/credited01.png" : "assets/images/icon_card.png";
          });
          this.transacationHistoryList = data.result.qrCodeTranHistory;
          this.collectionSize = data.result.totalRecords;
          this.changeDetectorRef.markForCheck();
        } else if (data.statusCode = 404) {
          this._toastrService.error(data.message, 'Error');
        } else {
          this._toastrService.error(data.message, 'Server Error');
        }
      }, error => {
        this._toastrService.error(error.statusText, 'Server Error');
      })
    });
  }
  transacation_details(transacationid) {
    this.router.navigate(['/transaction-history/', transacationid], { relativeTo: this.activatedRoute });
  }
}