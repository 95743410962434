import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {  ErrorMessage } from 'src/app/shared/constant/global-constants';

@Component({
  selector: 'app-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss']
})
export class ForgotPinComponent implements OnInit {

  otpCodeType = "password";
  otpCodeShow = false;

  newPinType = "password";
  newPinShow = false;

  confirmPinType = "password";
  confirmPinShow = false;

  forgotpinform: FormGroup

  constructor(
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService,
  ) { }

  ngOnInit() {

    this._service.get_observe('/sendforgotpinotp').subscribe(res => {
      if (res.status == 200) {
        if (res.body["statusCode"] == 200) {
          this._toastrService.success(res.body["message"]);
        }
        else {
          this._toastrService.warning(res.body["message"]);
        }
      } else {
        this._toastrService.error(ErrorMessage);
      }
    })

    //var retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    //var loggedinUserId = retrievedUserObject["userId"];

    this.forgotpinform = this._formBuilder.group({
     // userId: [loggedinUserId],
      otpCode: ['', Validators.required],
      newPin: ['', [Validators.required]],
      confirmPin: ['', [Validators.required]],
    })
  }

  saveForgotPin() {
    if (this.forgotpinform.invalid) {
      return;
    }

    this._service.post_observe('/setnewpin', this.forgotpinform.value).subscribe(res => {
      if (res.status == 200) {
        if (res.body["statusCode"] == 200) {
          this.forgotpinform.reset();
          this._toastrService.success(res.body["message"]);
        }
        else {
          this._toastrService.warning(res.body["message"]);
        }
      } else {
        this._toastrService.error(ErrorMessage);
      }
    })
  }

  toggleShowotpCode() {
    this.otpCodeShow = !this.otpCodeShow;
    if (this.otpCodeShow) {
      this.otpCodeType = "text";
    }
    else {
      this.otpCodeType = "password";
    }
  }

  toggleShowNewPin() {
    this.newPinShow = !this.newPinShow;
    if (this.newPinShow) {
      this.newPinType = "text";
    }
    else {
      this.newPinType = "password";
    }
  }

  toggleShowConfirmPin() {
    this.confirmPinShow = !this.confirmPinShow;
    if (this.confirmPinShow) {
      this.confirmPinType = "text";
    }
    else {
      this.confirmPinType = "password";
    }
  }
}
