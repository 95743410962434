import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../auth/service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regExp } from '../../constant/global-constants';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../../services/common/common.service';
import { Router, ActivatedRoute, Event, NavigationEnd, NavigationError } from '@angular/router';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  _bsModalRef: BsModalRef;
  userImage: any;
  isUserLogin = false;
  submitted: boolean = false;
  NotificationList = [];
  notificationDetails: any;
  totalNotification: number;
  totalPage: number;
  paymentmethod: any = environment.PAYMENT_METHOD_WALLET;
  currentPage: number = 1;
  loadpage = true;
  selector: string = '.scroll-ul';

  @ViewChild("registertemplate", { static: true }) registertemplate: ElementRef;

  initWallet: FormGroup;
  returnURL: string = window.location.href;
  constructor(private _modalService: BsModalService,
    private _authService: AuthService,
    private _fromBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private _service: CommonService,
    private _route: Router,
    private _activeroute: ActivatedRoute) {
    this.initWallet = this._fromBuilder.group({
      "userId": ['', Validators.required],
      "amount": ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      "returnURL": ['', Validators.required],
      "deviceTypeId": '3',
      "instrumentType": ['2', Validators.required]
    })
  }

  ngOnInit() {
    this._route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        //this.returnURL = window.location.href;
        this.returnURL = window.location.origin + '/wallet-status';
      }
      if (event instanceof NavigationError) {
        // Present error to user
      }
    });
    this._authService.isUserLoggedIn()
    this.isUserLogin = this._authService.isUserLoggedInStatus;
    if (this._activeroute.snapshot.queryParams['emailverify']) {
      this.verifyemail(this._activeroute.snapshot.queryParams['emailverify']);
    }
    if (this.isUserLogin) {
      this.userImage = JSON.parse(localStorage.getItem('currentUser'));
      if (this._activeroute.snapshot.queryParams['ref']) {
        let redirecturl = window.location.pathname;
        //let redirecturl =  'wallet-status';
        this.updatewallettopupstatus(this._activeroute.snapshot.queryParams['ref'], this.userImage.userId, redirecturl);
      }
      //this.getAllNotification();
      this.notificationcountupdate();
    }
    //this._modalService.show(this.registertemplate, {class:'youraccount',backdrop: 'static'});
  }

  verifyemail(emailverify) {
    this._service.get('verifyemailaddress?emailverify=' + emailverify).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this._toastrService.success(res.message, 'Success');
      } else {
        this._toastrService.error(res.message, 'Error');
      }
    });
  }

  ngDoCheck() {
    this._service.checkIfEmpty();
  }

  updatewallettopupstatus(transactionId, userId, redirecturl) {
    let data = {
      "userId": userId,
      "transactionId": transactionId,
      "status": 7,
      //"amount": localStorage.getItem('amount'),
      //"instrumentType": localStorage.getItem('instrumentType'),
      //"deviceTypeId": 3
    }
    localStorage.setItem('transactionId', transactionId);
    //this._service.post('updatewallettopupstatus', data).subscribe((res: any) => {
    this._service.post('updatetransactionstatus', data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this._toastrService.success('Transection success.', 'Success')
        this._route.navigate([redirecturl]);
        //localStorage.setItem('transactionId', transactionId);
        this.getwalletdetail();
      } else {
        this._toastrService.error(res.message)
      }
    }, error => {
      let fieldError = ""
      let errorObj = error['error'].error
      let errorMessage = error['error'].message
      if (errorObj) {
        Object.keys(errorObj).forEach(ele => {
          fieldError += errorObj[ele].message + '<br />'
        })
      } else {
        fieldError = "Something went wrong, please try again or after sometime."
      }
      this._toastrService.error(fieldError, errorMessage)
    })
  }

  getwalletdetail() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let data = {
      "walletCode": user.walletCode
    }
    this._service.post('getwalletdetail', data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        user.walletAmount = res.result.availableBalance;
        this.userImage = user;
        localStorage.setItem('currentUser', JSON.stringify(user));
      } else {
        this._toastrService.error(res.message)
      }
    });
  }

  initiatewallettopup() {
    this.submitted = true
    this.returnURL = window.location.origin + '/wallet-status';
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.initWallet.patchValue({ userId: user.userId, returnURL: this.returnURL })
    // stop here if form is invalid
    if (this.initWallet.invalid) {
      return;
    }
    this._service.post('initiatewallettopup', this.initWallet.value).subscribe((res: any) => {
      if (res.statusCode == 200) {
        localStorage.setItem('amount', this.initWallet.get('amount').value);
        localStorage.setItem('instrumentType', this.initWallet.get('instrumentType').value);
        window.location.href = res.result.paymentRedirectURL;
      } else {
        this._toastrService.error(res.message)
      }
      this.submitted = false;
    }, error => {
      let fieldError = ""
      let errorObj = error['error'].error
      let errorMessage = error['error'].message
      if (errorObj) {
        Object.keys(errorObj).forEach(ele => {
          fieldError += errorObj[ele].message + '<br />'
        })
      } else {
        fieldError = "Something went wrong, please try again or after sometime."
      }
      this._toastrService.error(errorMessage, 'Error')
    })
  }

  openLoginModal(popup) {
    this._bsModalRef = this._modalService.show(popup, { class: 'signin', backdrop: 'static', keyboard: false });
  }

  receiveLoginMessage(event) {
    this.userImage = JSON.parse(localStorage.getItem('currentUser'));
    this.getwalletdetail();
    if (this.userImage) {
      // this.getAllNotification()
      this.notificationcountupdate();
    }
    this.isUserLogin = event;
  }

  receiveRegisterMessage(event) {
    //this.
  }

  logoutUser() {
    this._authService.logout();
  }

  forgotopen(event, popup) {
    this._bsModalRef.hide();
    this._modalService.show(popup, { class: 'forgot-password', backdrop: 'static', keyboard: false });
  }

  registeropen(event, popup) {
    this._bsModalRef.hide();
    this._modalService.show(popup, { class: 'youraccount', backdrop: 'static', keyboard: false });
  }
  getStartNotification() {
    // this.loadpage = false;
    let userId: number;
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    if (user_data) {
      userId = user_data.userId;
    }
    if (this.NotificationList.length == 0) {
      let data = {
        "pageNumber": this.currentPage,
        "pageSize": 10,
        "sortField": "notificationDate",
        "sortOrder": "desc",
        "userId": userId
      }
      this._service.post('getnotificationlist', data).subscribe((res: any) => {
        res.result.notificationList.forEach(notification => {
          this.NotificationList.push(notification);
        });
        this.notificationcountupdate();
        this.totalPage = res.result.totalRecords;
      });
    }
  }
  getAllNotification() {
    let userId: number;
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    if (user_data) {
      userId = user_data.userId;
    }

    let data = {
      "pageNumber": this.currentPage,
      "pageSize": 10,
      "sortField": "notificationDate",
      "sortOrder": "desc",
      "userId": userId
    }
    this._service.post('getnotificationlist', data).subscribe((res: any) => {
      res.result.notificationList.forEach(notification => {
        this.NotificationList.push(notification);
      });
      this.notificationcountupdate();
      this.totalPage = res.result.totalRecords;
    });
  }

  onScroll() {
    if (this.currentPage >= 0 && this.totalPage != this.currentPage) {
      this.currentPage = this.currentPage + 1;
      this.getAllNotification();
    }
  }
  notificationcountupdate() {
    let userId: number;
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    if (user_data) {
      userId = user_data.userId;
      this._service.post('getunreadnotificationcount', { "userId": userId }).subscribe((res: any) => {
        this.totalNotification = res.result.count;
      });
    }
  }
  notificationdetailopen(popup, notificationDetails) {
    this.notificationDetails = notificationDetails;
    this._modalService.show(popup, { class: 'offer-detail' });
  }
  clearAllNotification() {
    let userId: number;
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    if (user_data) {
      userId = user_data.userId;
    }

    this._service.post('clearnotificationlist', { "userId": userId }).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.currentPage = 1;
        this.NotificationList = [];
        // this.getAllNotification();
      } else {
        this._toastrService.error(res.message)
      }
      this.submitted = false;
    }, error => {
      let fieldError = ""
      let errorObj = error['error'].error
      let errorMessage = error['error'].message
      if (errorObj) {
        Object.keys(errorObj).forEach(ele => {
          fieldError += errorObj[ele].message + '<br />'
        })
      } else {
        fieldError = "Something went wrong, please try again or after sometime."
      }
      this._toastrService.error(errorMessage, 'Error')
    })
  }


}


