import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-transacation-details',
  templateUrl: './transacation-details.component.html',
  styleUrls: ['./transacation-details.component.scss']
})
export class TransacationDetailsComponent implements OnInit {

  transactionHistorydetails: any = [];
  lblPaidToText: string;
  lblPaidto: string;
  lblPaidfrom: string;
  lblPaidimg: string;
  Statusclass: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _service: CommonService,
  ) { }

  ngOnInit() {
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    let userId = user_data.userId;
    this.activatedRoute.params.subscribe(params => {
      const transacationId = params['id'];
      let data = {
        "userId": userId,
        "transactionId": transacationId
      };

      this._service.post('gettransactiondetail', data).subscribe((res: any) => {
        if (res.status && res.result) {
          if (res.result.tranTypeName == "Debit") {
            res.result.lblPaidToText = "Paid To";
            res.result.lblPaidto = res.result.paidTo;
            res.result.lblPaidfrom = res.result.paymentFrom;
          } else if (res.result.tranTypeName == "WalletTopup") {
            res.result.lblPaidToText = "Wallet Topup";
            res.result.lblPaidto = res.result.paymentFrom;
            res.result.lblPaidfrom = res.result.paidTo;
          }
          else if (res.result.tranTypeName == "Credit") {
            if (user_data.userType == 2) {
              res.result.lblPaidToText = "Paid To";
              res.result.lblPaidto = res.result.paidTo;
              res.result.lblPaidfrom = res.result.paymentFrom;
            } else {
              res.result.lblPaidToText = "Paid By";
              res.result.lblPaidto = res.result.paymentFrom;
              res.result.lblPaidfrom = res.result.paidTo;
            }
          }
          else {
            res.result.lblPaidToText = "Paid To";
            res.result.lblPaidto = res.result.paidTo;
            res.result.lblPaidfrom = res.result.paymentFrom;
          }
          if (res.result.transactionStatus == "Success") {
            res.result.Statusclass = "transstatus success";
          } else if (res.result.transactionStatus == "Failed") {
            res.result.Statusclass = "transstatus fail";
          } else {
            res.result.Statusclass = "";
          }

          res.result.lblPaidimg = res.result.transactionMode == "Wallet" ? "assets/images/credited01.png" : "assets/images/icon_card.png";
          this.transactionHistorydetails = res.result;
        }
      });
    });
  }

}
