import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiURL: any = environment.API_ENDPOINT
  currentUser: any
  getToken: any
  isUserLoggedInStatus: boolean = false

  constructor(
    private _router: Router,
    private _http: HttpClient
  ) { }

  /**
   * 
   */
  isUserLoggedIn() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.getToken = localStorage.getItem('token')
    this.isUserLoggedInStatus = (this.getToken && this.currentUser) ? true : false
  }
  /**
   * 
   * @param url
   * @param userDetail 
   */
  post(url: any, userDetail) {
    return this._http.post(this.apiURL + url, userDetail, { observe: 'response' })
  }

  /**
   * 
   * @param url 
   */
  get(url: any) {
    return this._http.get(this.apiURL + url, { observe: 'response' })
  }

  
  logout() {
    localStorage.clear()
    this.isUserLoggedInStatus = false
    window.location.href= "/"
  }
}
