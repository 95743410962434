import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { regExp } from './../../../shared/constant/global-constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common/common.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  showforgotpass: boolean = true;
  showresetpassword: boolean = false;

  forgotPassForm: FormGroup;
  passwordForm: FormGroup;
  submitted = false;
  linkSend: boolean = false;
  isNewToken: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _modalService: BsModalService,
    private _service: CommonService,
  ) {
    this._authService.isUserLoggedInStatus ? this._router.navigate(['/']) : ''
    this.forgotPassForm = this._formBuilder.group({
      emailId: ['', [Validators.required, Validators.pattern(regExp.emailRegEx)]]
    });
    this.passwordForm = this._formBuilder.group({
      emailId: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', Validators.required],
      resetCode: ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]]
    }, { validator: this.MustMatch('newPassword', 'confirm_password') })
  }

  ngOnInit() {
    this.showforgotpass = true;
    
  }

  get forgotControl() { return this.forgotPassForm.controls; }
  get passwordControl() { return this.passwordForm.controls }

  forgotPassword() {
    this.submitted = true
    if (this.forgotPassForm.invalid) {
      return;
    }
    this._service.post('forgotpassword', this.forgotPassForm.value).subscribe((res:any) => {
      if (res.statusCode == 200) {
          this._toastrService.success(res.message);
          this.showforgotpass = false;
          this.showresetpassword = true;
          this.submitted = false;
      } else {
        this._toastrService.error(res.message);
        this.submitted = false;
      }
    })
  }

  createPassword() {
    this.submitted = true
    this.passwordForm.patchValue({emailId:this.forgotPassForm.get('emailId').value});
    if (this.passwordForm.invalid) {
      return;
    }
    this._service.post('resetpassword', this.passwordForm.value).subscribe((res:any) => {
      if (res.statusCode == 200) {
          this.passwordForm.reset();
          this._toastrService.success(res.message);
          this.closeForgotPassModal();
          this.showforgotpass = true;
          this.showresetpassword = false;
      } else {
        this._toastrService.error(res.message);
      }
    })
  }

  closeForgotPassModal() {
    this._modalService._hideModal(1);
    //this._modalService.hide(1);
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}
