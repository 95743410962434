import { Component, OnInit, HostListener } from '@angular/core';
import { FooterCompanyName } from './../../constant/global-constants';
import { environment } from '../../../../environments/environment';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public year = new Date().getFullYear()
  public companyName = FooterCompanyName
  FB_URL = environment.FB_URL;
  TWITTER_URL = environment.TWITTER_URL;
  GOOGLE_URL = environment.GOOGLE_URL;
  IN_URL = environment.IN_URL;
  ANDROID_APP_URL = environment.ANDROID_APP_URL;
  IOS_APP_URL = environment.IOS_APP_URL;
  serviceslist: any;
  showScroll: boolean;
  showScrollHeight = 500;
  hideScrollHeight = 10;
  constructor(private _service: CommonService) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
      this.showScroll = true;
    }
    else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
      this.showScroll = false;
    }
  }

  ngOnInit() {
    this._service.currentMessage.subscribe(services => this.serviceslist = services)
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

}
