import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private _service: CommonService) { }

  policylist: any;
  
  ngOnInit() {
    let data = {"type": 1};
    this._service.post('getagreementpage',data).subscribe((res: any) => {
      this.policylist = res.result.contentList;
    });
    
  }

}

