import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loadershow: boolean = false;
  apiURL: any = environment.API_ENDPOINT
  apiURL_2: any = environment.API_ENDPOINT_2
  private messageSource = new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();
  constructor(
    private _http: HttpClient
  ) { }

  /**
   * 
   * @param url 
   */
  
  post(url: any, data: any) {
    return this._http.post(this.apiURL + url, data)
  }

  get(url: any) {
    return this._http.get(this.apiURL + url)
  }

  get_observe(url: any) {
    return this._http.get(this.apiURL + url, { observe: 'response' })
  }

  post_observe(url: any, data: any) {
    return this._http.post(this.apiURL + url, data, { observe: 'response' })
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          resolve({ lng: '', lat: '' });
        });
    });
  }

  showloader() {
    this.loadershow = true;
  }

  hideloader() {
    this.loadershow = false;
  }

  changeMessage(services: any) {
    this.messageSource.next(services)
  }

  // call second url APIS
  get_2(url: any) {
    let headers = { headers: { "IntegrationKey": environment.BILLER_KEY } };
    return this._http.get(this.apiURL_2 + url, headers);
  }

  post_2(url: any, data: any) {
    let headers = { headers: { "IntegrationKey": environment.BILLER_KEY } };
    return this._http.post(this.apiURL_2 + url, data, headers);
  }

  checkIfEmpty() {
    jQuery('.valuefocus').each(function () {
      if (!jQuery(this).val()) {
        jQuery(this).parent().removeClass("fieldfocus");
      } else {
        jQuery(this).parent().addClass("fieldfocus");
      }
    });
  }

}
