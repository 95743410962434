import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {

  @ViewChild('formele', { static: false }) formele: ElementRef;

  filterForm: any;
  status=[];
  Statussorting_class: string;
  Datesorting_class: string;
  Amountsorting_class: string;
  hasFormErrors: boolean = false;
  montharr = [];
  retrievedUserObject: any;
  minDate = new Date();
  _filter: any = {};
  maxDate = new Date(
    this.minDate.getFullYear() - 1,
    this.minDate.getMonth(),
    this.minDate.getDate()
  );
  selectedItems = [


  ];
  dropdownSettings = {
    singleSelection: false,
    text: "Select",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class"
  };
  transaction;
  transactionFilter = [{ id: 2, itemName: 'Payement Received' }, { id: 1, itemName: 'Payement Sent' }];
  //status = [{ id: 7, itemName: 'Success' }, { id: 8, itemName: 'Fail' }, { id: 9, itemName: 'Cancel' }];
  paymentOption = [{ id: 1, itemName: 'Wallet' }, { id: 2, itemName: 'Card' }, { id: 5, itemName: 'Bank' }];
  transactionType = [{ id: "Individual-Corporate", itemName: 'Individual to Corporate' }, { id: "Corporate-Individual", itemName: 'Corporate to Individual' }, { id: "Corporate-Corporate", itemName: 'Corporate to Corporate' }];
  pageSize: number = 10;
  collectionSize: number;
  page: number = 1;
  orderBy: string = 'desc';
  orderByItem: string = 'transactionDate';
  loggedinUserId;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,

  ) {
  this.retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    var monthStart = moment(this.maxDate);
    var monthEnd = moment(this.minDate);

    while (monthEnd > monthStart || monthStart.format('M') === monthEnd.format('M')) {
      this.montharr.push({ id: monthStart.format('MMM-YYYY'), itemName: monthStart.format('MMM-YYYY') });
      monthStart.add(1, 'month');
    }
    this.montharr.reverse();
    let data = {"statusType" : 2};
    this._service.post('getstatuslist',data).subscribe((res: any) => {
      if (res.status && res.result) {
        res.result.statusList.forEach(statusdata => {
          this.status.push({ id : statusdata.id , itemName:statusdata.stausType});
        })
      }
    });
    
    this.filterForm = {
      month: [[]],
      status: [[]],
      transactionType: [[]],
      transactionFilter: [[]],
      paymentOption: [[]],

    };
    this.retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    this.loggedinUserId = this.retrievedUserObject["userId"];

    //    const this._filter: any = {};.profileImage = this.user.profileImage;


    this.get_page();
  }
  transacation_details(transacationid) {
    this.router.navigate(['/transaction-history/', transacationid], { relativeTo: this.activatedRoute });
  }
  pageChanged(event: any): void {
    this.page = event.page;
    this.get_page();
    this.gotoTop();
  }

  get_page() {
    this._filter.userId = this.loggedinUserId;
    this._filter.transactionType = [];
    if (this.filterForm['transactionType'].length) {
      this.filterForm['transactionType'].forEach(it => {
        if (it.id != undefined && it.id != null)
          this._filter.transactionType.push(it.id)
      })
    }
    this._filter.transactionFilter = [];
    if (this.filterForm['transactionFilter'].length) {
      this.filterForm['transactionFilter'].forEach(it => {
        if (it.id != undefined)
          this._filter.transactionFilter.push(it.id)
      })
    }
    this._filter.paymentOption = [];
    if (this.filterForm['paymentOption'].length) {
      this.filterForm['paymentOption'].forEach(it => {
        if (it.id != undefined)
          this._filter.paymentOption.push(it.id)
      })
    }
    this._filter.status = [];
    if (this.filterForm['status'].length) {
      this.filterForm['status'].forEach(it => {
        if (it.id != undefined)
          this._filter.status.push(it.id)
      })
    }
    this._filter.month = [];
    if (this.filterForm['month'].length) {
      this.filterForm['month'].forEach(it => {
        if (it.id != undefined)
          this._filter.month.push(it.id)
      })
    }
    this._filter['pageNumber'] = Number(this.page) - 1;
    this._filter['deviceTypeId'] = 1;
    this._filter['pageSize'] = this.pageSize;
    this._filter['sortField'] = this.orderByItem;
    this._filter['sortOrder'] = this.orderBy;
    this.loadList();

  }
  sorting(sortField, sortOrder) {
    this.orderByItem = sortField;
    this.orderBy = sortOrder;
    this.page = 1;
    //this.sorting_class = sortOrder;
    if (sortField == "transactionDate" && sortOrder == "asc") {
      this.Datesorting_class = 'Dateasc';
    } else {
      this.Datesorting_class = "Datedesc"
    }
    if (sortField == "transactionAmount" && sortOrder == "asc") {
      this.Amountsorting_class = 'Amountasc';
    } else {
      this.Amountsorting_class = "Amountdesc"
    }
    if (sortField == "transactionStatus" && sortOrder == "asc") {
      this.Statussorting_class = 'Statusasc';
    } else {
      this.Statussorting_class = "Statusdesc"
    }

    this.get_page();
  }
  onItemSelect($event) {
    this.page = 1;
    this.get_page();

  }

  gotoTop() {
    this.formele.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  lblPaidToText(a) {
    let lblPaidToText = '';
    if (a.tranTypeName == "Debit") {
      lblPaidToText = "Paid To";

    } else if (a.tranTypeName == "WalletTopup") {
      lblPaidToText = "Wallet Topup";
    }
    else if (a.tranTypeName == "Credit") {
      if (this.retrievedUserObject["userType"] == 2) {
        lblPaidToText = "Paid To";

      } else {
        lblPaidToText = "Paid By";

      }
    }
    else {
      lblPaidToText = "Paid To";

    }
    return lblPaidToText;
  }
  lblPaidto(a) {
    let lblPaidto = '';
    if (a.tranTypeName == "Debit") {
      lblPaidto = a.paidTo;
    } else if (a.tranTypeName == "WalletTopup") {
      lblPaidto = a.paymentFrom;
    }
    else if (a.tranTypeName == "Credit") {
      if (this.retrievedUserObject["userType"] == 2) {
        lblPaidto = a.paidTo;
      } else {
        lblPaidto = a.paymentFrom;
      }
    }
    else {
      lblPaidto = a.paidTo;
    }
    return lblPaidto;
  }
  statusclass(a) {
    let Statusclass = '';
    if (a.transactionStatus == "Success") {
      Statusclass = "success";
    } else if (a.transactionStatus == "Failed") {
      Statusclass = "fail";
    } else {
      Statusclass = "";
    }
    return Statusclass
  }
  lblPaidimg(a) {
    let lblPaidimg = a.transactionMode == "Wallet" ? "assets/images/credited01.png" : "assets/images/icon_card.png";
    return lblPaidimg;
  }
  loadList() {
    let res = this._service.post('getcorporatetransactionhistory', this._filter).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.transaction = data.result.corporateHistory;
        this.collectionSize = data.result.totalRecords;
        this.changeDetectorRef.markForCheck();
      } else if (data.statusCode = 404) {
        this._toastrService.error(data.message, 'Error');
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }

}
