import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { regExp, ErrorMessage } from 'src/app/shared/constant/global-constants';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {

  schedualWithdrawform: FormGroup
  //submitted = false
  createWithdraw: boolean = false;
  schedualWithdraw: boolean = true;
  ScheduleId: number;
  bankList: any;
  user: any;
  beneficiaryName: string = '';
  scheduleItems: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService
  ) {
    this.schedualWithdrawform = this._formBuilder.group({
      beneficiaryName: [''],
      bankCode: ['', Validators.required],
      accountNumber: ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      amount: ['', [Validators.required, Validators.pattern(regExp.numberRegEx)]],
      narration: ['', Validators.required],
      userId: [''],
      walletCode: [''],
    })
  }

  ngOnInit() {
    this.getAllbank();
  }

  SaveschedualWithdraw() {
    const controls = this.schedualWithdrawform.controls;
    if (this.schedualWithdrawform.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user) {
      let bankCode = controls['bankCode'].value ? controls['bankCode'].value : '';
      let accountNumber = controls['accountNumber'].value ? controls['accountNumber'].value : '';
      this.bankValidate(bankCode, accountNumber);
    }
  }

  bankValidate(bankCode, accountNumber) {
    let data = { "bankCode": bankCode, "accountId": accountNumber }
    this._service.post_2('billers/bankaccount/name', data).subscribe((res: any) => {
      if (res.code == 'PY00' && res.succeeded) {
        this.beneficiaryName = res.data.accountName ? res.data.accountName : '';
        this.createschedule();
      } else {
        this._toastrService.error(res.message)
      }
    }, err => {
      this._toastrService.error(err.error.message)
    });
  }
  createschedule() {
    this.schedualWithdrawform.patchValue({ 'userId': this.user.userId, 'walletCode': this.user.walletCode, 'beneficiaryName': this.beneficiaryName });
    // let data = {
    //   "userId": 3064,
    //   "beneficiaryName": "Money Transfer11",
    //   "amount": "30000",
    //   "accountNumber": "0014261063",
    //   "bankCode": "058",
    //   "narration": "test transaction 11",
    //   "walletCode": "7b1e541a-c0f6-4d65-40fc-08d77884a105"
    // }
    // this.schedualWithdrawform.value
    this._service.post_observe('/createscheduleitem', this.schedualWithdrawform.value).subscribe(res => {
      if (res.status == 200) {
        if (res.body['statusCode'] == 200) {
          this.scheduleItems = res.body['result'].scheduleItems[0];
          this.ScheduleId = res.body["result"].scheduleId;
          this.createWithdraw = true;
          this.schedualWithdraw = false;
          this._toastrService.success(res.body["message"]);
        } else {
          this._toastrService.warning(res.body["message"]);
        }
      } else {
        this._toastrService.error(ErrorMessage);
      }
    })
  }
  payScheduale() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user) {
      let data = { 'scheduleId': this.ScheduleId }
      this._service.post_observe('/processscheduleid', data).subscribe(res => {
        if (res.status == 200) {
          if (res.body['statusCode'] == 200) {
            this.createWithdraw = false;
            this.schedualWithdraw = true;
            this.schedualWithdrawform.reset();
            this._toastrService.success(res.body["message"]);
          } else {
            this._toastrService.warning(res.body["message"]);
          }
        } else {
          this._toastrService.error(ErrorMessage);
        }
      })
    }
  }

  getAllbank() {
    this._service.get_2('billers/banks').subscribe((res: any) => {
      if (res.code == 'PY00') {
        this.bankList = res.data.banks;
      } else {
        this._toastrService.error(res.message)
      }
    }, error => {
      this._toastrService.error('Something went wrong, please try again or after sometime.', 'Error')
    });
  }
}
