import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { regExp } from './../../../shared/constant/global-constants';
import { CommonService } from '../../../services/common/common.service';
import { timer, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { BsModalService } from 'ngx-bootstrap';
import { AuthService as socialauthreg } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Output() loginsuccess = new EventEmitter<any>();

  regForm: FormGroup;
  mobileForm: FormGroup;
  verifyForm: FormGroup;
  pinForm: FormGroup;
  regCorpForm: FormGroup;

  submitted = false;
  countryList: any;
  storeCategory: any;
  inputpassword: string;

  mobilemodal: boolean = true;
  verifymodal: boolean = false;
  personalmodal: boolean = false;
  generatepinmodal: boolean = false;
  corporatemodal: boolean = false;

  countryCode: string;
  mobileNo: string;
  UserType: number;
  otp: string;
  counter: number = environment.OTP_TIME;
  lat: any;
  lng: any;
  userId: any;
  isSocialLogin: any;

  OtpType = "password";
  OtpShow = false;

  PinType = "password";
  PinShow = false;

  ConfirmPinType = "password";
  ConfirmPinShow = false;

  formData = new FormData();
  registersubscribe: Subscription;
  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _service: CommonService,
    private _toastrService: ToastrService,
    private _modalService: BsModalService,
    private socialauthreg: socialauthreg
  ) {
    this._authService.isUserLoggedInStatus ? this._router.navigate(['/']) : ''
    this.mobileForm = this._formBuilder.group({
      UserType: ['1', Validators.required],
      countryCode: ['+234', Validators.required],
      mobileNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(regExp.numberRegEx)]]
    });
    this.verifyForm = this._formBuilder.group({
      otp: ['', Validators.required],
      UserType: [''],
      countryCode: [''],
      mobileNo: ['']
    });

    let passwordValid = [
      Validators.minLength(6),
    ];
    let cnfValid = [];
    let termsnconditon = [];
    console.log("constract..",localStorage.getItem('isRegister'));
    if (localStorage.getItem('isRegister') == null) {
      console.log("in validation.........")
      passwordValid.push(Validators.required);
      cnfValid.push(Validators.required);
      termsnconditon.push(Validators.required);
    }

    this.regForm = this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(regExp.emailRegEx)]],
      //password: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', Validators.compose(passwordValid)],
      //cpassword: ['', Validators.required],
      cpassword: ['', Validators.compose(cnfValid)],
      //termsnconditon: ['', Validators.requiredTrue],
      termsnconditon: ['', Validators.compose(termsnconditon)],
      mobileNo: [this.mobileNo],
      userType: [this.UserType],
      countryCode: [this.countryCode],
      faceBookId: [''],
      googleId: [''],
      isSocialLogin:[0]
    }, { validator: this.MustMatch('password', 'cpassword') });
    this.pinForm = this._formBuilder.group({
      pinNumber: ['', Validators.required],
      pincNumber: ['', [Validators.minLength(6), Validators.pattern(regExp.numberRegEx)]],
      userId: ['']
    }, { validator: this.MustMatch('pinNumber', 'pincNumber') });
    this.regCorpForm = this._formBuilder.group({
      firstName: [''],
      lastName: [''],
      emailId: [''],
      password: [''],
      termsnconditon: ['', Validators.requiredTrue],
      mobileNo: [this.mobileNo],
      userType: [this.UserType],
      countryCode: [this.countryCode],
      businessName: ['', Validators.required],
      ownerName: ['', Validators.required],
      aboutBusiness: ['', Validators.required],
      address: ['', Validators.required],
      businessCards: ['', Validators.required],
      businessDocuments: ['', Validators.required],
      businessCategoryId: ['', Validators.required],
      IpAddress: [''],
      OsVersion: [''],
      zipLatitude: [''],
      zipLongitude: [''],
      faceBookId: [''],
      googleId: ['']
    });
  }

  ngOnInit() {
    this.getCountryCode();
    this.getBusinessCategory();
    console.log("oniniti....",localStorage.getItem('isRegister') );
    this.registersubscribe = this.socialauthreg.authState.subscribe((user) => {
      
      if (user) {
        if (user.provider === "GOOGLE") {
          this.regForm.patchValue({ 'googleId': user.id, 'firstName': user.firstName, 'lastName': user.lastName, 'emailId': user.email });
          this.regCorpForm.patchValue({ 'googleId': user.id, 'firstName': user.firstName, 'lastName': user.lastName, 'emailId': user.email });
        } else if (user.provider === "FACEBOOK") {
          this.regForm.patchValue({ 'faceBookId': user.id, 'firstName': user.firstName, 'lastName': user.lastName, 'emailId': user.email });
          this.regCorpForm.patchValue({ 'faceBookId': user.id, 'firstName': user.firstName, 'lastName': user.lastName, 'emailId': user.email });
        }
        this.signOut();
      }
    });
  }

  signInWithGoogle(): void {
    this.socialauthreg.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.socialauthreg.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialauthreg.signOut();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  generatepinnumber() {
    this.submitted = true;
    if (this.pinForm.valid) {
      //let user = JSON.parse(localStorage.getItem('currentUser'));
      this.pinForm.patchValue({ 'userId': this.userId });
      this._service.post('generatepinnumber', this.pinForm.value).subscribe((data: any) => {
        this.submitted = false;
        if (data.statusCode == 200) {
          this.generatepinmodal = false;
          this.closeModal();
          this._toastrService.success(data.message, 'Success');
        } else if (data.statusCode = 404) {
          this._toastrService.error(data.message, 'Error');
        } else {
          this._toastrService.error(data.message, 'Server Error');
        }
      }, error => {
        this._toastrService.error(error.statusText, 'Server Error');
      })
    }
  }

  userRegister() {
    this.submitted = true;
    console.log("in register");
    this.regForm.patchValue({ 'userType': this.UserType, 'countryCode': this.countryCode, 'mobileNo': this.mobileNo });
    console.log(this.regForm.value);
    if (this.regForm.valid) {
      console.log("in rege form valid",localStorage.getItem('isRegister'));
      if (this.UserType == 1) { // individual user
      
        if (localStorage.getItem('isRegister') == null) { this.isSocialLogin = 0; } else { this.isSocialLogin = 1; }
        console.log("this.isSocialLogin ", this.isSocialLogin);
        this.regForm.patchValue({ 'isSocialLogin': this.isSocialLogin });
        console.log( "form value...",this.regForm.value);
        this._service.post('individualregister', this.regForm.value).subscribe((data: any) => {
          this.submitted = false;
          if (data.statusCode == 200) {
            localStorage.setItem('token', data.result.authToken);
            localStorage.setItem('currentUser', JSON.stringify(data.result))
            localStorage.removeItem('isRegister');
            this.userId = data.result.userId;
            this.personalmodal = false;
            this.generatepinmodal = true;
            this._toastrService.success(data.message, 'Success');
            //this.loginsuccess.emit(true);
          } else if (data.statusCode = 404) {
            this._toastrService.error(data.message, 'Error');
          } else {
            this._toastrService.error(data.message, 'Server Error');
          }
        }, error => {
          this._toastrService.error(error.statusText, 'Server Error');
        })
      } else if (this.UserType == 2) { // corporate user
        this.submitted = false;
        this.personalmodal = false;
        this.corporatemodal = true;
      } else {
        this._toastrService.error('Error, Please refresh page and try again.', 'Error');
      }
    }
  }

  corporateRegister() {
    this.submitted = true;
    this.regCorpForm.patchValue({
      'userType': this.UserType,
      'countryCode': this.countryCode,
      'mobileNo': this.mobileNo,
      'firstName': this.regForm.get('firstName').value,
      'lastName': this.regForm.get('lastName').value,
      'emailId': this.regForm.get('emailId').value,
      'password': this.regForm.get('password').value,
      'termsnconditon': this.regForm.get('termsnconditon').value
    });
    if (this.regCorpForm.valid) {
      if (this.UserType == 2) { // corporate user
        this._service.post('corporateregister', this.toFormData(this.regCorpForm.value)).subscribe((data: any) => {
          this.submitted = false;
          if (data.statusCode == 200) {
            //localStorage.setItem('token', data.result.authToken);
            //localStorage.setItem('currentUser', JSON.stringify(data.result))
            this.userId = data.result.userId;
            this.corporatemodal = false;
            this.generatepinmodal = true;
            this._toastrService.success(data.message, 'Success');
            this.loginsuccess.emit(true);
          } else if (data.statusCode = 404) {
            this._toastrService.error(data.message, 'Error');
          } else {
            this._toastrService.error(data.message, 'Server Error');
          }
        }, error => {
          this._toastrService.error(error.statusText, 'Server Error');
        })
      } else if (this.UserType == 1) { // individual user
        this.personalmodal = true;
        this.corporatemodal = false;
      } else {
        this._toastrService.error('Error, Please refresh page and try again.', 'Error');
      }
    }
  }

  gobacktopersonal() {
    this.personalmodal = true;
    this.corporatemodal = false;
    this.formData = new FormData();
  }

  toFormData<T>(formValue: T) {
    for (const key of Object.keys(formValue)) {
      if (key != 'businessCards' && key != 'businessDocuments') {
        const value = formValue[key];
        if (this.formData.has(key)) {
          this.formData.set(key, value);
        } else {
          this.formData.append(key, value);
        }
      }
    }
    return this.formData;
  }

  onFileChange(event, field) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.formData.append(field, file);
    }
  }

  customTimer() {
    const source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.counter = environment.OTP_TIME - val;
      if (this.counter == 0) {
        abc.unsubscribe();
      }
    });
  }

  getCountryCode() {
    this._service.get('getcountrylist').subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.countryList = data.result.countryList;
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }

  sendmobileotp() {
    this.submitted = true;
    if (this.mobileForm.valid) {
      this.UserType = this.mobileForm.get('UserType').value;
      this.countryCode = this.mobileForm.get('countryCode').value;
      this.mobileNo = this.mobileForm.get('mobileNo').value;
      this._service.post('sendmobileotp', this.mobileForm.value).subscribe((data: any) => {
        this.submitted = false;
        if (data.statusCode == 200) {
          this.mobilemodal = false;
          this.verifymodal = true;
          this.customTimer();
          this._toastrService.success(data.message, 'Success');
        } else {
          this._toastrService.error(data.message, 'Server Error');
        }
      }, error => {
        this._toastrService.error(error.statusText, 'Server Error');
      })
    }
  }

  verifymobile() {
    this.verifyForm.patchValue({ 'UserType': this.UserType, 'countryCode': this.countryCode, 'mobileNo': this.mobileNo });
    this.submitted = true;
    if (this.verifyForm.valid) {
      this.otp = this.verifyForm.get('otp').value;
      this._service.post('verifymobileotp', this.verifyForm.value).subscribe((data: any) => {
        this.submitted = false;
        if (data.statusCode == 200) {
          this.verifymodal = false;
         
          console.log("sdfdsfds...",localStorage.getItem('isRegister') );
          if (localStorage.getItem('isRegister') == null) {
            console.log(1)
            this.personalmodal = true;
          } else {
            console.log(2)
            this.personalmodal = false;
            this.generatepinmodal = true;
            this.userRegister();
           
          }
          this._toastrService.success(data.message, 'Success');
        } else if (data.statusCode = 404) {
          this._toastrService.error(data.message, 'Error');
        } else {
          this._toastrService.error(data.message, 'Server Error');
        }
      }, error => {
        this._toastrService.error(error.statusText, 'Server Error');
      });
    }
  }

  closeModal() {
    this.registersubscribe.unsubscribe();
    this._modalService._hideModal(1);
  }

  getBusinessCategory() {
    this._service.get('getstorecategory').subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.storeCategory = data.result.storeCategory;
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }

  getlatlng(popup) {
    this._service.getPosition().then(pos => {
      if (pos.lat == "" || pos.lng == "") {
        this._toastrService.error("Allow location permission to perform operation.", 'Need Permission');
      } else {
        this.lat = pos.lat;
        this.lng = pos.lng;
        this._modalService.show(popup, { class: 'address-map', backdrop: 'static', keyboard: false });
      }
    });
  }

  toggleShowOtp() {
    this.OtpShow = !this.OtpShow;
    if (this.OtpShow) {
      this.OtpType = "text";
    }
    else {
      this.OtpType = "password";
    }
  }

  toggleShowPin() {
    this.PinShow = !this.PinShow;
    if (this.PinShow) {
      this.PinType = "text";
    }
    else {
      this.PinType = "password";
    }
  }

  toggleShowConfirmPin() {
    this.ConfirmPinShow = !this.ConfirmPinShow;
    if (this.ConfirmPinShow) {
      this.ConfirmPinType = "text";
    }
    else {
      this.ConfirmPinType = "password";
    }
  }
  onPassChange(event) {
    this.inputpassword = event.target.value;
  }

}