import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessage } from 'src/app/shared/constant/global-constants';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements OnInit {

  oldPinType = "password";
  oldPinShow = false;

  newPinType = "password";
  newPinShow = false;

  confirmPinType = "password";
  confirmPinShow = false;

  changepinform: FormGroup

  constructor(
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService,
  ) { }

  ngOnInit() {

    //var retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    //var loggedinUserId = retrievedUserObject["userId"];

    this.changepinform = this._formBuilder.group({
     // userId: [loggedinUserId],
      oldPin: ['', Validators.required],
      newPin: ['', [Validators.required]],
      confirmPin: ['', [Validators.required]],
    })
  }

  saveChangePin() {
    if (this.changepinform.invalid) {
      return;
    }

    this._service.post_observe('/changepin', this.changepinform.value).subscribe(res => {
      if (res.status == 200) {
        if (res.body["statusCode"] == 200) {
          this.changepinform.reset();
          this._toastrService.success(res.body["message"]);
        }
        else {
          this._toastrService.warning(res.body["message"]);
        }
      } else {
        this._toastrService.error(ErrorMessage);
      }
    })
  }

  toggleShowOldPin() {
    this.oldPinShow = !this.oldPinShow;
    if (this.oldPinShow) {
      this.oldPinType = "text";
    }
    else {
      this.oldPinType = "password";
    }
  }

  toggleShowNewPin() {
    this.newPinShow = !this.newPinShow;
    if (this.newPinShow) {
      this.newPinType = "text";
    }
    else {
      this.newPinType = "password";
    }
  }

  toggleShowConfirmPin() {
    this.confirmPinShow = !this.confirmPinShow;
    if (this.confirmPinShow) {
      this.confirmPinType = "text";
    }
    else {
      this.confirmPinType = "password";
    }
  }
}
