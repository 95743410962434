import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';
import { CommonService } from '../../../services/common/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SRUserDetail } from './../../../models/sr-user';
import { regExp, ErrorMessage } from 'src/app/shared/constant/global-constants';
import { Router, ActivatedRoute } from '@angular/router';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-add-sruser',
  templateUrl: './add-sruser.component.html',
  styleUrls: ['./add-sruser.component.scss']
})
export class AddSruserComponent implements OnInit {

  @Input() userDetail: any;
  result = true;
  user: SRUserDetail;
  countryList: any;
  userList: any;
  inputpassword:string;
  action: string = "Add ";
  SRUserForm: FormGroup;
  hasFormErrors: boolean = false;
  myDate = new Date();
  maxDate = new Date(
    this.myDate.getFullYear() - 10,
    this.myDate.getMonth(),
    this.myDate.getDate()
  );

  PasswordType = "password";
  PasswordShow = false;

  confirmPasswordType = "password";
  confirmPasswordShow = false;



  constructor(
    private _modalService: BsModalService,
    private _service: CommonService,
    private _toastrService: ToastrService,
    private SRUserFB: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getCountryCode();
    if (this.userDetail) {
      //of(undefined).pipe(take(1), delay(200)).subscribe(() => {
      this.action = "Edit ";
      this.user = this.userDetail;
      this.user.mobileNo = this.userDetail.mobileNumber;
      this.createForm();
      this.changeDetectorRef.markForCheck();
      // });
    } else {
      of(undefined).pipe(take(1), delay(200)).subscribe(() => {
        this.action = "Add ";
        this.user = new SRUserDetail();
        this.user.clear();
        this.user.dateOfBirth = "";
        this.createForm();
        this.changeDetectorRef.markForCheck();
      });
    }




  }

  closeModal() {
    this._modalService._hideModal(1);
  }

  createForm() {

    let passwordValid = 
      [
        Validators.minLength(6),
        //Validators.pattern(regExp.passwordRegEx)
      ];
	
    let cnfValid = [];
    if (!this.user.userId) {
      passwordValid.push(Validators.required);
      cnfValid.push(Validators.required);
    }


    this.SRUserForm = this.SRUserFB.group({
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      emailId: [this.user.emailId, [Validators.required, Validators.pattern(regExp.emailRegEx)]],
      dateOfBirth: [this.user.dateOfBirth, Validators.required],
      gender: [this.user.gender, Validators.required],
      password: ['', Validators.compose(passwordValid)],
      cpassword: ['', Validators.compose(cnfValid)],
      mobileNo: [this.user.mobileNo, [Validators.required, Validators.minLength(10), Validators.pattern(regExp.numberRegEx)]],
      countryCode: ['+234'],
    }, { validator: this.MustMatch('password', 'cpassword') });
    //this.changeDetectorRef.markForCheck();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.SRUserForm.controls;

    if (this.SRUserForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    const editedUsers = this.prepareUser();
    if (editedUsers._user.userId > 0) {
      this.updateUsers(editedUsers._user);
      return;
    }

    this.addUsers(editedUsers._user);

  }

  prepareUser(): any {
    const controls = this.SRUserForm.controls;
    const _user: any = {};
    let user_data = JSON.parse(localStorage.getItem('currentUser'));
    let corporateUserId = user_data.userId;
    if (corporateUserId) {
      _user.corporateUserId = corporateUserId;
    }
    _user.userId = this.user.userId;
    let dt = controls['dateOfBirth'].value;
    let dtSend = moment(dt).format('YYYY-MM-DD');
    _user.firstName = controls['firstName'].value;
    _user.lastName = controls['lastName'].value;
    _user.emailId = controls['emailId'].value;
    _user.gender = controls['gender'].value;
    _user.mobileNo = controls['mobileNo'].value;
    //_user.countryCode = controls['countryCode'].value;
    _user.countryCode = "+234";
    _user.password = controls['password'].value;
    _user.dateOfBirth = dtSend;
    const formData = new FormData();
    Object.keys(_user).forEach(item => {
      formData.append(item, _user[item]);
    })

    return { form: formData, _user: _user };
  }

  addUsers(_user: any) {
    let res = this._service.post('addsalesrepresentative', _user).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this._modalService._hideModal(1);
        this._toastrService.success(data.message, 'Success');
      } else if (data.statusCode = 404) {
        this._toastrService.error(data.message, 'Error');
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }

  updateUsers(_user: any) {
    let res = this._service.post('editsalesrepresentative', _user).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this._modalService._hideModal(1);
        this._toastrService.success(data.message, 'Success');
      } else if (data.statusCode = 404) {
        this._toastrService.error(data.message, 'Error');
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.SRUserForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType);
    return result;
  }
  getCountryCode() {
    this._service.get('/getcountrylist').subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.countryList = data.result.countryList;
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }

  toggleShowPassowrd() {
    this.PasswordShow = !this.PasswordShow;
    if (this.PasswordShow) {
      this.PasswordType = "text";
    }
    else {
      this.PasswordType = "password";
    }
  }

  toggleShowConfirmPassowrd() {
    this.confirmPasswordShow = !this.confirmPasswordShow;
    if (this.confirmPasswordShow) {
      this.confirmPasswordType = "text";
    }
    else {
      this.confirmPasswordType = "password";
    }
  }
  onPassChange(event) {
    this.inputpassword = event.target.value;
  }

}

