import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { regExp, ErrorMessage } from 'src/app/shared/constant/global-constants';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  oldPasswordType = "password";
  oldPasswordShow = false;

  newPasswordType = "password";
  newPasswordShow = false;

  confirmPasswordType = "password";
  confirmPasswordShow = false;

  changepasswordform: FormGroup
  inputpassword:string;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService,
    private _authService: AuthService
  ) { }

  ngOnInit() {

    var retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    var loggedinUserId = retrievedUserObject["userId"];
    
    this.changepasswordform = this._formBuilder.group({
      userId: [loggedinUserId],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],
    })
  }

  saveChangePassword() {
    if (this.changepasswordform.invalid) {
      return;
    }

    this._service.post_observe('/changepassword', this.changepasswordform.value).subscribe(res => {
      if (res.status == 200) {
        if (res.body["statusCode"] == 200) {
          this.changepasswordform.reset();
          this._toastrService.success(res.body["message"]);
        }
        else {
          this._toastrService.warning(res.body["message"]);
        }
      } else {
        this._toastrService.error(ErrorMessage);
      }
    })
  }

  toggleShowOldPassowrd() {
    this.oldPasswordShow = !this.oldPasswordShow;
    if (this.oldPasswordShow) {
      this.oldPasswordType = "text";
    }
    else {
      this.oldPasswordType = "password";
    }
  }

  toggleShowNewPassowrd() {
    this.newPasswordShow = !this.newPasswordShow;
    if (this.newPasswordShow) {
      this.newPasswordType = "text";
    }
    else {
      this.newPasswordType = "password";
    }
  }

  toggleShowConfirmPassowrd() {
    this.confirmPasswordShow = !this.confirmPasswordShow;
    if (this.confirmPasswordShow) {
      this.confirmPasswordType = "text";
    }
    else {
      this.confirmPasswordType = "password";
    }
  }
  onPassChange(event) {
    this.inputpassword = event.target.value;
  }

}
