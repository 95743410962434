import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private _service: CommonService, private _route: Router, ) { }
  slideConfig = { "slidesToShow": 3, "slidesToScroll": 1, "dots": true, "infinite": false, "centerMode": false, "speed": 1000, responsive: [{ breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } }, { breakpoint: 576, settings: { slidesToShow: 1, slidesToScroll: 1 } }] };
  slideConfigHome = { autoplay: false, dots: true, infinite: true, centerMode: false, adaptiveHeight: true, speed: 1500 };
  nearbystorelist: any;
  mostpopularappslist: any = [];
  offerlist: any;
  categorylist: any;
  lat: any = '';
  lng: any = '';
  type: any = 'list';
  userId: any = '';
  store: any;
  defaultStoreImage: string = "assets/images/homedummyimg-bg.png";
  defaultBannerImage: string = "assets/images/home-banner-default.png";
  defaultAppImage: string = "assets/images/recharge-default-home.png";
  defaultServiceImage: string = "assets/images/recharge-default-home.png";

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && user.userId && user.userId != '') {
      this.userId = user.userId
    }

    this._service.getPosition().then(pos => {
      this.lat = pos.lat;
      this.lng = pos.lng;
      this.type = 'list';
      this.getnearbystorelist();
    }).catch(error => {
      this.getnearbystorelist();
    });
    this.getofferlist();
    this.getmostpopularappslist();
    this.getcategorylist();
  }
  GonearByStore(store) {
    this._route.navigate(['nearby-store'], { state: { store: store } });
  }

  getmostpopularappslist() {
    this._service.get('getappslist').subscribe((res: any) => {
      if (res.status == 1 && res.statusCode == 200) {
        res.result.categories.forEach(category => {
          if (category.categoriesName == 'Popular') {
            if (category.categoryAppList.length >= 1) {
              this.mostpopularappslist.push(category.categoryAppList[0]);
            }
            if (category.categoryAppList.length >= 2) {
              this.mostpopularappslist.push(category.categoryAppList[1]);
            }
            if (category.categoryAppList.length >= 3) {
              this.mostpopularappslist.push(category.categoryAppList[2]);
            }
            if (category.categoryAppList.length >= 4) {
              this.mostpopularappslist.push(category.categoryAppList[3]);
            }
          }
        });
      }
    });
  }

  getnearbystorelist() {
    let data = {
      "latitude": this.lat,
      "longitude": this.lng,
      "PageNumber": 1,
      "type": "list",
      "CategoryId": [],
      "userId": this.userId,
      "searchKey": "",
      "isFromSearch": "1"
    };
    this._service.post('getstorelist', data).subscribe((res: any) => {
      this.nearbystorelist = res.result.storeList;
    });
  }

  getofferlist() {
    let data = { "offerType": 1 };
    this._service.post('getalloffer', data).subscribe((res: any) => {
      this.offerlist = res.result.offerList;
    });
  }

  getcategorylist() {
    this._service.get('getcategorylist').subscribe((res: any) => {
      this.categorylist = res.result.categoryList;
    });
  }
}
