import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
import { QRCodeModule } from 'angularx-qrcode';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  userForm: FormGroup;
  hasFormErrors: boolean = false;
  countryList: any;
  user: any; country: any = [];
  state: any = [];
  town: any = [];
  zip: any = [];
  bizImage: any = '';
  editProfile: boolean = false;
  qrcode;
  previewURL: any;
  defImage: string = 'assets/images/browse-image.png';
  myDate = new Date();
  maxDate = new Date(
    this.myDate.getFullYear() - 10,
    this.myDate.getMonth(),
    this.myDate.getDate()
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.router.url.includes('edit')) {
      this.editProfile = true;
    }
    var retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    var loggedinUserId = retrievedUserObject["userId"];
    let req = {
      "userId": loggedinUserId
    };
    this.getCountry();
    this._service.post_observe('getuserprofile', req).subscribe(data => {
      if (data.status && data.body) {
        let res = data.body['result'];
        this.getState(res.countryId);
        this.getTown(res.provinceId);
        //this.getZip(res.townId);
        of(undefined).pipe(take(1), delay(200)).subscribe(() => {
          this.user = res;
          if (this.user.dateOfBirth) {
            this.user.dateOfBirth = moment(this.user.dateOfBirth).format('YYYY-MM-DD')
          } else {
            this.user.dateOfBirth = '';
          }
          if (this.editProfile) {
            this.initUser();
          }
        })
      } else {
        if (this.editProfile) {
          this.initUser();
        }
      }
    });
    this._service.post('getuserqrcode', req).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.qrcode = data.result['qrCodeId']
      }
    });
    //this.getUserData();
  }

  initUser() {
    if (this.user.dateOfBirth) {
      this.user.dateOfBirth = moment(this.user.dateOfBirth).format('YYYY-MM-DD')
    } else {
      this.user.dateOfBirth = '';
    }
    this.userForm = this._formBuilder.group({
      dateOfBirth: [this.user.dateOfBirth, Validators.required],
      gender: [this.user.gender, Validators.required],
      profilePicture: [''],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      countryId: [this.user.countryId, Validators.required],
      provinceId: [this.user.provinceId, Validators.required],
      townId: [this.user.townId, Validators.required],
      zipCode: [this.user.zipCode, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.pattern("^[0-9]*$")
      ])],
    });
    this.changeDetectorRef.markForCheck();
  }
  getUserData() {
    //this._service.post_observe(this.userprofileform,null).subscribe(res => {

    //})
  }
  onFileSelected(event) {
    let files = event.target.files;

    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {

      return;
    }
    if (files[0]) {
      this.bizImage = files[0];
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.previewURL = reader.result;
    }
  }
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.userForm.controls;
    if (this.userForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;

      return;
    }

    const editedUser = this.prepareUser();

    this.updateUser(editedUser.form);
    return;



  }
  prepareUser(): any {
    const controls = this.userForm.controls;
    const _user: any = {};
    //_user.profileImage = this.user.profileImage;
    if (this.user.userId) {
      _user.userId = this.user.userId;
    }
    let dt = controls['dateOfBirth'].value;
    let dtSend = moment(dt).format('YYYY-MM-DD');
    _user.firstName = controls['firstName'].value;
    _user.lastName = controls['lastName'].value;
    _user.gender = controls['gender'].value;
    _user.townId = controls['townId'].value;
    _user.provinceId = controls['provinceId'].value;
    _user.countryId = controls['countryId'].value;
    _user.zipCode = controls['zipCode'].value;
    _user.dateOfBirth = dtSend;
    const formBisData = new FormData();
    Object.keys(_user).forEach(item => {
      formBisData.append(item, _user[item]);
    })
    if (this.bizImage != null && this.bizImage != '') {
      formBisData.append('profilePicture', this.bizImage);
    }
    return { form: formBisData, _user: _user };
  }
  updateUser(_user: any) {
    let res = this._service.post('edituserprofile', _user).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this._toastrService.success(data.message, 'Success');
        let currUser = JSON.parse(localStorage.getItem('currentUser'))
        currUser.firstName = data.result['firstName'];
        currUser.lastName = data.result['lastName'];
        currUser.profilePicture = data.result['profilePicture'];
        currUser.dateOfBirth = data.result['dateOfBirth'];
        localStorage.setItem('currentUser', JSON.stringify(currUser));
        this.changeDetectorRef.markForCheck();
        this.router.navigate(['/my-profile/'], { relativeTo: this.activatedRoute });
      } else if (data.statusCode = 404) {
        this._toastrService.error(data.message, 'Error');
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })


  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.userForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType);
    return result;
  }
  getCountry() {
    this._service.get_observe('/getcountrylist').subscribe(data => {
      if (data.status && data.body) {
        this.country = data.body['result']['countryList'];
      } else {
        this.country = [];
      }

    });

  }
  getState(val) {
    this._service.post_observe('/getprovincelist', { 'countryId': val }).subscribe(data => {
      if (data.status && data.body) {
        this.state = data.body['result']['provinceList'];
      } else {
        this.state = [];
      }
      this.town = [];
      this.zip = [];
    });
  }
  getTown(val) {
    this._service.post_observe('/gettownlist', { 'provinceId': val }).subscribe(data => {
      if (data.status && data.body) {
        this.town = data.body['result']['townList'];
      } else {
        this.town = [];
      }
      this.zip = [];
    });

  }
  /*getZip(val) {
    this._service.post_observe('/getzipcodelist', { 'townId': val }).subscribe(data => {
      if (data.status && data.body) {
        this.zip = data.body['result']['zipCodeList'];
      } else {
        this.zip = [];
      }

    });

  }*/
}
