import { Component, OnInit, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../services/common/common.service';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {

  @Input() offerId:any;
  offerdetail: any;
  constructor(private _modalService: BsModalService,
    private _service: CommonService,
    ) { }

  ngOnInit() {
    this.getofferdetail();
  }

  closeModal(){
    this._modalService._hideModal(1);
  }

  getofferdetail() {
    let data = {
      offerId: this.offerId
    }
    this._service.post('getofferdetail',data).subscribe((res: any) => {
      this.offerdetail = res.result;
    });
  }

}
