import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent implements OnInit {
  filterForm: FormGroup;
  hasFormErrors: boolean = false;
  public pdfObject: SafeHtml;
  public pdfSrc;
  minDate = new Date();
  maxDate = new Date(
    this.minDate.getFullYear(),
    this.minDate.getMonth() - 1,
    this.minDate.getDate()
  );
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _service: CommonService,
    private _toastrService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) {


  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {

    this.filterForm = this._formBuilder.group({
      transactionType: ['Individual-Corporate', Validators.required],
      fromDate: [this.maxDate, Validators.required],
      toDate: [this.minDate, Validators.required],
    });
    this.changeDetectorRef.markForCheck();
  }
  onSumbit() {
    this.hasFormErrors = false;
    const controls = this.filterForm.controls;
    if (this.filterForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;

      return;
    }
    var retrievedUserObject = JSON.parse(localStorage.getItem('currentUser'));
    var loggedinUserId = retrievedUserObject["userId"];
    const _item: any = {};
    //    const _item: any = {};.profileImage = this.user.profileImage;

    _item.userId = loggedinUserId;

    let fromDate = moment(controls['fromDate'].value).format('YYYY-MM-DD');
    let toDate = moment(controls['toDate'].value).format('YYYY-MM-DD');
    _item.transactionType = controls['transactionType'].value;
    _item.fromDate = fromDate;
    _item.toDate = toDate;

    let res = this._service.post('generatetransactionreport', _item).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this._toastrService.success(data.message, 'Success');
        this.pdfObject = '';
        this.pdfSrc = '';

        if (data.result['pdfLink'] != null) {
          this.pdfSrc = data.result['pdfLink'];
          window.open(this.pdfSrc);
        }
      } else if (data.statusCode = 404) {
        this._toastrService.error(data.message, 'Error');
      } else {
        this._toastrService.error(data.message, 'Server Error');
      }
    }, error => {
      this._toastrService.error(error.statusText, 'Server Error');
    })
  }
  public setInnerHtml(pdfurl: string) {
    this.pdfObject = this._sanitizer.bypassSecurityTrustHtml(
      "<object data='" + pdfurl + "' type='application/pdf' class='embed-responsive-item'>" +
      "Object " + pdfurl + " failed" +
      "</object>");
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.filterForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType);
    return result;
  }
}
