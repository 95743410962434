import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpConfigInterceptor } from './auth/interceptor/httpconfing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AccordionModule, BsDatepickerModule, ModalModule, PaginationModule,TabsModule,BsDropdownModule  } from 'ngx-bootstrap';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { PaginationDetailComponent } from './components/common/pagination-detail/pagination-detail.component';
import { PopupComponent } from './shared/components/popup/popup.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './auth/components/login/login.component';
import { SlickModule } from 'ngx-slick';
import { FaqComponent } from './components/faq/faq.component';
import { NearbyStoreComponent } from './components/nearby-store/nearby-store.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { ForgotPasswordComponent } from '../../src/app/auth/components/forgot-password/forgot-password.component';
import { RegisterComponent } from '../../src/app/auth/components/register/register.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../src/environments/environment';
import { SettingsComponent } from './components/settings/settings.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { MyProfileComponent } from 'src/app/components/my-profile/my-profile.component';
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { MainHomeComponent } from './components/main-home/main-home.component';
import { TermsConditionsMobileComponent } from './components/terms-conditions-mobile/terms-conditions-mobile.component';
import { PrivacyPolicyMobileComponent } from './components/privacy-policy-mobile/privacy-policy-mobile.component';
import { AboutusMobileComponent } from './components/aboutus-mobile/aboutus-mobile.component';
import { OfferAndDiscountComponent } from './components/offer-and-discount/offer-and-discount.component';
import { GetAppComponent } from './components/get-app/get-app.component';
import { OfferDetailComponent } from './components/offer-and-discount/offer-detail/offer-detail.component';
import { MapPopupComponent } from './auth/components/register/map-popup/map-popup.component';
import { ServicesDetailsComponent } from './components/services-details/services-details.component';
import { StringreplacePipe } from './shared/pipes/stringreplace.pipe'
import { StripHtmlPipe } from './shared/pipes/striphtml.pipe';
import { ShortenPipe } from './shared/pipes/shorten.pipe';
import { AmountPipe } from './shared/pipes/amount.pipe';
import { TransacationDetailsComponent } from './components/transacation-details/transacation-details.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ManageSRUsersComponent ,BlockComponent,DeactivateComponent} from './components/manage-srusers/manage-srusers.component';
import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';
import { RevenueReportComponent } from './components/revenue-report/revenue-report.component';
import { ServicesStatusComponent } from './components/services-status/services-status.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { AddSruserComponent } from './components/manage-srusers/add-sruser/add-sruser.component';
import { SrQrcodeHistoryComponent } from './components/manage-srusers/sr-qrcode-history/sr-qrcode-history.component';
import { SrTransacationHistoryComponent } from './components/manage-srusers/sr-transacation-history/sr-transacation-history.component';
import { NoaccessComponent } from './components/noaccess/noaccess.component';
import { ChangePinComponent } from './components/change-pin/change-pin.component';
import { ForgotPinComponent } from './components/forgot-pin/forgot-pin.component';
import { UtcDatePipe } from './shared/pipes/utc-date.pipe';
import { WalletStatusComponent } from './components/wallet-status/wallet-status.component';
import { NotificationDetailComponent } from './components/home/notification-detail/notification-detail.component';
import { WithdrawComponent } from './components/withdraw/withdraw.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GPlUS_KEY)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FB_ID)
  }
]);
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    AboutusComponent,
    ContactusComponent,
    PageNotFoundComponent,
    LoaderComponent,
    PaginationDetailComponent,
    PopupComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    FaqComponent,
    NearbyStoreComponent,
    TermsConditionsComponent,
    SettingsComponent,
    ChangePasswordComponent,
    MyProfileComponent,
    TermsConditionsMobileComponent,
    MainHomeComponent,
    PrivacyPolicyMobileComponent,
    AboutusMobileComponent,
    GetAppComponent,
    OfferAndDiscountComponent,
    OfferDetailComponent,
    MapPopupComponent,
    StripHtmlPipe,
    ShortenPipe,
    AmountPipe,
    TransacationDetailsComponent,
    ServicesDetailsComponent,
    StringreplacePipe,
    TransactionReportComponent,
    RevenueReportComponent,
    ServicesStatusComponent,
    ManageSRUsersComponent,
    BlockComponent,
    DeactivateComponent,
    AddSruserComponent,
    SrQrcodeHistoryComponent,
    SrTransacationHistoryComponent,
    TransactionHistoryComponent,
    NoaccessComponent,
    ChangePinComponent,
    ForgotPinComponent,
    UtcDatePipe,
    WalletStatusComponent,
    NotificationDetailComponent,
    WithdrawComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    SlickModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    InfiniteScrollModule,
    SocialLoginModule,
    AgmCoreModule.forRoot({apiKey: environment.MAP_KEY,libraries: ['places']}),
    ToastrModule.forRoot({timeOut: 5000, closeButton: true, enableHtml: true, positionClass: 'toast-bottom-right', preventDuplicates: true, progressBar: true}),
    QRCodeModule,
    PasswordStrengthMeterModule
  ],
  exports: [StripHtmlPipe,UtcDatePipe,ShortenPipe,AmountPipe],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpConfigInterceptor, 
      multi: true,
      
    },{
      provide: AuthServiceConfig, 
      useFactory: provideConfig,
    },
    
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BlockComponent,
    DeactivateComponent
	],
})
export class AppModule { }

