import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { regExp } from './../../../shared/constant/global-constants';
import { AuthService } from '../../service/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService as socialauth } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  bsModalRef: BsModalRef;
  loginModalRef: LoginComponent
  submitted = false;
  @Output() loginsuccess = new EventEmitter<any>();
  @Output() forgotopen = new EventEmitter<any>();
  @Output() registeropen = new EventEmitter<any>();
  loginsubscribe: Subscription;
  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _modalService: BsModalService,
    private socialauth: socialauth
  ) {
    this._authService.isUserLoggedInStatus ? this._router.navigate(['/']) : ''
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      emailid: ['', [Validators.required, Validators.pattern(regExp.emailRegEx)]],
      password: ['', Validators.required],
      usertype: ['1', Validators.required]
    })

    this.loginsubscribe = this.socialauth.authState.subscribe((user) => {
      if (user) {
        let data = {
          "userType": this.loginForm.get('usertype').value,
          "faceBookId": null,
          "googleId": null,
          "emailId":user.email ? user.email : ''
        }
        // if (user.provider === "GOOGLE") {
        //   data.googleId = user.id;
        // } else if (user.provider === "FACEBOOK") {
        //   data.faceBookId = user.id;
        // }
        this._authService.post('socialmedialogin', data).subscribe(res => {
          if (res.body["statusCode"] == 200) {
            let data = res.body['result']
            if(data.isRegister){
              localStorage.setItem('currentUser', JSON.stringify(data))
              localStorage.setItem('token', data.authToken)
              this.loginsuccess.emit(true);
              this._authService.isUserLoggedIn()
              this._modalService.hide(1);
              this._modalService._hideModal(1);
              this._toastrService.success('success', 'Login')
            }else{
              console.log("in elese");
              localStorage.setItem('isRegister', data.isRegister)
              this.loginsubscribe.unsubscribe();
              this.registeropen.emit(true)
            }
          } else {
            this._toastrService.warning(res.body["message"])
          }
          this.signOut();
        }, error => {
          let fieldError = ""
          let errorObj = error['error'].error
          let errorMessage = error['error'].message
          if (errorObj) {
            Object.keys(errorObj).forEach(ele => {
              fieldError += errorObj[ele].message + '<br />'
            })
          } else {
            fieldError = "Something went wrong, please try again or after sometime."
          }
          this._toastrService.error(fieldError, errorMessage)
        });
      }
    });
  }

  signInWithGoogle(): void {
    this.socialauth.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.socialauth.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialauth.signOut();
  }

  get loginControl() { return this.loginForm.controls; }

  login() {
    this.submitted = true
    // if (this.loginForm.invalid) {
    //   return;
    // }
    const controls = this.loginForm.controls;
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this._authService.post('login', this.loginForm.value).subscribe(res => {
      if (res.body["statusCode"] == 200) {
        let data = res.body['result']
        localStorage.setItem('currentUser', JSON.stringify(data))
        localStorage.setItem('token', data.authToken)
        this.loginsuccess.emit(true);
        this._authService.isUserLoggedIn()
        this._modalService._hideModal(1);
        //this._modalService.hide(1);
        if (data.userType != 1) {
          this._router.navigate(['/my-dashobard']);
        }
        this._toastrService.success('success', 'Login')
      } else {
        this._toastrService.warning(res.body["message"])
      }
    }, error => {
      let fieldError = ""
      let errorObj = error['error'].error
      let errorMessage = error['error'].message
      if (errorObj) {
        Object.keys(errorObj).forEach(ele => {
          fieldError += errorObj[ele].message + '<br />'
        })
      } else {
        fieldError = "Something went wrong, please try again or after sometime."
      }
      this._toastrService.error(fieldError, errorMessage)
    })
  }

  openForgotPasswordModal(popup) {
    this.forgotopen.emit(true)
  }

  openRegisterModal(popup) {
    this.loginsubscribe.unsubscribe();
    this.registeropen.emit(true)
  }

  closeModal() {
    this._modalService._hideModal(1);
  }
}
