import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-terms-conditions-mobile',
  templateUrl: './terms-conditions-mobile.component.html',
  styleUrls: ['./terms-conditions-mobile.component.scss']
})
export class TermsConditionsMobileComponent implements OnInit {

  constructor(private _service: CommonService) { }

  termconditionlist: any;
  
  ngOnInit() {
    let data = {"type": 2};
    this._service.post('getagreementpage',data).subscribe((res: any) => {
      this.termconditionlist = res.result.contentList;
    });
    
  }

}

