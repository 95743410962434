/**
 * This constant file is used for development environment
 * Summary: All constant values
 */

export const HeaderTitle = 'Cyberworld'
export const FooterCompanyName = 'Krish Compusoft Services'
export const itemsPerPage = 3
export const regExp = {
  emailRegEx: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
  passwordRegEx: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[-_\.]).{8,}$/,
  numberRegEx: /^[0-9]+$/,
}
export const Aboutus_slug = 'About_Us'
export const ErrorMessage="Something went wrong, Please try again after some time."
