import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-get-app',
  templateUrl: './get-app.component.html',
  styleUrls: ['./get-app.component.scss']
})
export class GetAppComponent implements OnInit {
  appslist: any = [];
  defaultImage: string = "assets/images/recharge-default-home.png";

  constructor(private _service: CommonService) { }

  ngOnInit() {
    this.getappslist();
  }

  getappslist() {
    this._service.get('getappslist').subscribe((res: any) => {
      if (res.status == 1 && res.statusCode == 200) {
        this.appslist = res.result.categories;
      }
    });
  }

}
