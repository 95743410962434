import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    let date = new Date(value + 'z');
    // console.log(value, date);
    return date;
  }

}
